import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../LandingPage/Home/Home';
import Admin from '../Module/Admin/Dashboard/Dashboard';
import Group from '../Module/Admin/Group/Group';
import Principal from '../Module/Principal/Dashboard/Dashboard';
import Groups from '../Module/Principal/Groups/Groups';
import Teacher from '../Module/Teacher/Dashboard/Dashboard';
import MyStudent from '../Module/Teacher/MyStudent/MyStudent';
import Student from '../Module/Student/Dashboard/Dashboard';
import LoginPage from "../../src/Module/Login/Loginpage/LoginPage";
import ForgetPassword from '../../src/Module/Login/ForgetPassword/ForgetPassword';
import ResetPassword from '../../src/Module/Login/ResetPassword/ResetPassword';
import CreateAccount from '../Module/Signup/CreateAccount/CreateAccount'
import ConfirmationForm from '../Module/Signup/ConfirmationForm/ConfirmationForm';
import Principals from '../Module/Admin/Principals/Principals';
import CreateForm from '../Module/Admin/Principals/CreateForm';
import Teachers from '../Module/Admin/Teachers/Teachers';
import CreateForms from '../Module/Admin/Teachers/CreateForm';
import Students from '../Module/Admin/Students/Students';
import Forms from '../Module/Admin/Students/CreateForm';
import TeacherDataTable from '../Shared-Component/ViewLists/Teachers-Datatable/TeacherDataTable';
import MyStudents from '../Module/Principal/Students/Students';
import MobileProfileCard from '../Module/Student/MobileProfileCard';
import StudentInfo from '../Module/Student/Dashboard/StudentInformation/StudentInformation';
import MobilePersonalSatement from '../Module/Student/Dashboard/MobileStudentForm/MobilePersonalStatement/MobilePersonalStatement';
import MobileCareerGoalsForm from '../Module/Student/Dashboard/MobileStudentForm/Mobile-Career-Goals-Form/MobileCareerGoalsForm';
import MobileAcademicGoalsForm from '../Module/Student/Dashboard/MobileStudentForm/Mobile-Academic-Goal-Form/MobileAcademicGoalForm';
import MobileAcademicPathwaysForm from '../Module/Student/Dashboard/MobileStudentForm/Mobile-Academic-Pathways-Form/MobileAcademicPathwaysForm';
import MobileCheckListProgressForm from '../Module/Student/Dashboard/MobileStudentForm/Mobile-CheckList-Progress-Form/MobileCheckListProgressForm';
import MobileExtraActivitiesForm from '../Module/Student/Dashboard/MobileStudentForm/Mobile-Extra-Activities-Form/MobileExtraActivitiesForm';
import CreateGroup from '../Module/Admin/Group/CreateGroup';
import AboutUs from '../LandingPage/AboutUs/AboutUs';
import FAQ from '../LandingPage/FAQ/FAQ';
import System from '../LandingPage/System/System';
import Products from '../LandingPage/Products/Products';
import Modeling from '../LandingPage/Products/Modeling/Modeling';
import DataAnalytics from '../LandingPage/Products/Data-Analytics/Data-Analytics';
import Subscription from '../LandingPage/Subscription/Subscription';
import Testimonials from '../LandingPage/Testimonials/Testimonials';
import ContactUs from '../LandingPage/ContactUs/ContactUs';
import ResumeBuilder from '../Module/Student/Dashboard/Resume-Builder/Resume-Builder';
import StudentProfile from '../Module/Student/StudentProfile/Studentprofile';
import { MobileTestScoreForm } from '../Module/Student/Dashboard/MobileStudentForm/Mobile-Test-Score-Form/MobileTestScoreForm';
import Help from '../Shared-Component/Help-Section/Help';
import LaulamaGuides from '../Shared-Component/Help-Section/Laulama-Guides/LaulamaGuides';
import PersonalStatement from '../Shared-Component/Help-Section/Personal-Statement/PersonalStatement';
import Courses from '../Shared-Component/Help-Section/Courses/Courses';
import Resume from '../Shared-Component/Help-Section/Resume/Resume';
import PTPResources from '../Shared-Component/Help-Section/PTP-Resources/PTPResources';
import HelpResources from '../Shared-Component/Help-Section/Helps-Resources/HelpsResources';
import ViewProfile from '../Shared-Component/ViewProfile/ViewProfile';

function Navigation() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path='/faq' element={<FAQ/>} />
                    <Route path='/system' element={<System/>} />
                    <Route path='/products' element={<Products/>} />
                    <Route path='/modeling' element={<Modeling/>} />
                    <Route path='/dataanalytics' element={<DataAnalytics/>} />
                    <Route path='/subscription' element={<Subscription/>} />
                    <Route path='/stories' element={<Testimonials/>} />
                    <Route path='/contactus' element={<ContactUs/>} />

                { /*Login Routing */}
                
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/forgetpassword' element={<ForgetPassword />} />
                    <Route path='/resetpassword' element={<ResetPassword />} />

                { /*Signup Routing */}

                    <Route path='/signup' element={<CreateAccount />} />
                    <Route path='/signup/confirmcode' element={<ConfirmationForm />} />
                    

                   
                    <Route path="/admin/dashboard" element={<Admin />} />
                    <Route path="/admin/groups" element={<Group />} />
                    <Route path="/admin/groups/create" element={<CreateGroup />} />
                    <Route path="/admin/principals" element={<Principals />} />
                    <Route path="/admin/principals/create" element={<CreateForm />} />
                    <Route path="/admin/teachers" element={<Teachers />} />
                    <Route path="/admin/teachers/create" element={<CreateForms />} />
                    <Route path="/admin/students" element={<Students />} />
                    <Route path="/admin/students/create" element={<Forms />} />



                    <Route path='/principal/dashboard' element={<Principal />} />
                    <Route path='/principal/groups' element={<Groups />} />

                    <Route path='/principal/students' element={<MyStudents />} />
                    <Route path='/principal/teacherDataList' element={<TeacherDataTable />} />


                    <Route path='/teacher/dashboard' element={<Teacher />} />
                    <Route path='/teacher/studentsList' element={<MyStudent />} />

                    <Route path="/student/dashboard" element={<Student />} />
                    {/* <Route path="/student/profile" element={<MobileProfileCard />} /> */}
                    <Route path="/student/resumebuilder" element={<ResumeBuilder />} />
                    <Route path="/student/profile" element={<StudentProfile />} />

                    <Route path={'/' + ('teacher') + "/studentinfo"} element={<StudentInfo />} />
                    <Route path={'/' + ('principal') + "/studentinfo"} element={<StudentInfo />} />
                    <Route path={'/' + ('teacher') + "/profile"} element={<ViewProfile />} />
                    <Route path={'/' + ('principal') + "/profile"} element={<ViewProfile />} />

                    <Route path="/student/personalStatement" element={<MobilePersonalSatement/>} />
                    <Route path="/student/careergoals" element={<MobileCareerGoalsForm/>} />
                    <Route path="/student/academicgoals" element={<MobileAcademicGoalsForm />} />
                    <Route path="/student/academypathway" element={<MobileAcademicPathwaysForm />} />
                    {/* <Route path="/student/checklistprogress" element={<MobileCheckListProgressForm />} /> */}
                    <Route path="/student/highSchoolProgress" element={<MobileCheckListProgressForm />} />
                    <Route path="/student/testScore" element={<MobileTestScoreForm />} />
                    <Route path="/student/extraactivities" element={<MobileExtraActivitiesForm />} />

                    <Route path="/admin/help" element={<Help />} />
                    <Route path="/teacher/help" element={<Help />} />
                    <Route path="/principal/help" element={<Help />} />


                    <Route path="/student/help/laulamaGuides" element={<LaulamaGuides/>} />
                    <Route path="/student/help/personalStatement" element={<PersonalStatement/>} />
                    <Route path="/student/help/courses" element={<Courses/>} />
                    <Route path="/student/help/resume" element={<Resume/>} />
                    <Route path="/student/help/ptpResources" element={<PTPResources/>} />
                    <Route path="/student/help/helpResources" element={<HelpResources/>} />

                </Routes>
            </Router>
        </div>
    );
}

export default Navigation;

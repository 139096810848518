import React, { useEffect, useState } from 'react'
import StudentCard from '../../Shared-Component/Student-PersonalPathway-Card/Student-PersonalPathway-Card'
import { GET_STUDENT_DETAILS } from '../../Utils/StudentService';
import axios from 'axios';
import { CONTENT_HEADERS } from '../../Utils/LoginService';
import Spinner from '../../Shared-Component/Spinner/Gif-Loader';

const MobilePTPGoals = () => {

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    const [coreDrivers, setCoreDrivers] = useState();
    const [aptitudes, setAptitudes] = useState();
    const [riasecResults, setRiasecResults] = useState();
    const [careerGoals, setCareerGoals] = useState();
    const [educationalGoal, setEducationalGoal] = useState();
    const [academicCGPAGoals, setAcademicCGPAGoals] = useState();
    const [honorsRecognitionCertificate, setHonorsRecognitionCertificate] = useState();
    const [highSchoolAcademicGoals, setHighSchoolAcademicGoals] = useState();
    const [academiesCategoryName, setAcademiesCategoryName] = useState();

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });

        axios
            .get(GET_STUDENT_DETAILS, { headers: CONTENT_HEADERS })
            .then((response) => {
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
                const userData = response.data;

                if (userData.careerGoals?.youScienceResults.length > 0) {
                    const latestVersion = ((userData.careerGoals.youScienceResults.length) - 1);
                    const latestYouScience = userData.careerGoals.youScienceResults[latestVersion];
                    setCoreDrivers(latestYouScience?.coreDrivers);
                    setAptitudes(latestYouScience?.aptitudes);
                }

                if (userData.careerGoals?.riasecResults.length > 0) {
                    const latestVersion = ((userData.careerGoals.riasecResults.length) - 1);
                    const latestYouScience = userData.careerGoals.riasecResults[latestVersion];
                    setRiasecResults(latestYouScience.result);
                }

                if (userData.careerGoals?.careerGoalCategories.length > 0) {
                    const latestVersion = ((userData.careerGoals.careerGoalCategories.length) - 1);
                    const latestCareerGoals = userData.careerGoals.careerGoalCategories[latestVersion];
                    if (latestCareerGoals?.notDecided) {
                        setCareerGoals("Not Decided");
                    } else {
                        const data = latestCareerGoals.categories
                        if (data.length > 0) {
                            const categoryNames = data.map(item => item.categoryName);
                            setCareerGoals(categoryNames);
                        } else {
                            console.log("No data available.");
                        }
                    }
                }

                if (userData.academicGoals?.academicGoals.length > 0) {
                    const latestVersion = ((userData.academicGoals.academicGoals.length) - 1);
                    const latestAcademicGoals = userData.academicGoals.academicGoals[latestVersion];

                    const educationalGoalsOptions = latestAcademicGoals.goals
                        .filter(goal => goal.academicGoalsCategoryName === "Educational Goal")
                        .map(goal => goal.goalOptions);
                    setEducationalGoal(educationalGoalsOptions.length ? educationalGoalsOptions : "-");

                    const filteredAcademicCGPAGoal = latestAcademicGoals.goals.filter(goal => goal?.academicGoalsCategoryName === "Cumulative GPA Goal")
                        .map(goal => goal.goalOptions);
                    setAcademicCGPAGoals(filteredAcademicCGPAGoal.length ? filteredAcademicCGPAGoal : "-");


                    const filteredHonorRecognitionCertificateGoal = latestAcademicGoals.goals.filter(goal => goal?.academicGoalsCategoryName === "Honors Recognition Certificate Goal")
                        .map(goal => goal.goalOptions);
                    setHonorsRecognitionCertificate(filteredHonorRecognitionCertificateGoal.length ? filteredHonorRecognitionCertificateGoal : "-");

                    const filteredAfterHighSchoolGoal = latestAcademicGoals.goals.filter(goal => goal?.academicGoalsCategoryName === "After High School Plan")
                        .map(goal => goal.goalOptions);
                    setHighSchoolAcademicGoals(filteredAfterHighSchoolGoal.length ? filteredAfterHighSchoolGoal : "-");
                }

                if (userData.academiesPathways?.academiesPathwayVersion?.academiesPathway.length > 0) {
                    const latestVersion = ((userData.academiesPathways?.academiesPathwayVersion?.academiesPathway.length) - 1);
                    const latestAcademyPathway = userData?.academiesPathways?.academiesPathwayVersion?.academiesPathway[latestVersion];
                    const academicCategory = latestAcademyPathway?.academiesPathwayCategory?.find(category => category?.academiesPathWay.some(pathway => pathway !== null));
                    const categoryName = academicCategory ? academicCategory?.academiesCategoryName : null;
                    setAcademiesCategoryName(categoryName)
                }
            })
            .catch((error) => {
                setLoading(true);
                console.log(error);
            });
    }

    return (
        <>
            <div className="hide-on-large-screens tab-mob">
                <div className="spinner-section">
                    {loading ? <Spinner /> : null}
                </div>
                <div className="grid">
                    <div className='tab-mob-GR'>
                        <StudentCard
                            coreDrivers={coreDrivers}
                            aptitudes={aptitudes}
                            riasecResults={(
                                <div>
                                    {riasecResults && riasecResults.map((value, index) => (
                                        <div key={index}>{value}</div>
                                    ))}
                                </div>
                            )}
                            careerGoals={(
                                <div>
                                    {careerGoals && careerGoals.map((value, index) => (
                                        <div key={index}>{value}</div>
                                    ))}
                                </div>
                            )}
                            educationalGoal={educationalGoal}
                            academiCGPAGoals={academicCGPAGoals}
                            honorsRecognitionCertificate={honorsRecognitionCertificate}
                            highSchoolAcademicGoals={highSchoolAcademicGoals}
                            academiesCategoryName={academiesCategoryName}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobilePTPGoals;
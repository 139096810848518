import React from "react";
import "./status-card.scss";
import { Card } from 'primereact/card';
import { FaFlagCheckered, FaHourglassStart, FaUsers } from 'react-icons/fa';

const StatusCard = (props) => {

    const userRole = props.userRole;
    // let title;
    let card1data;
    let card2data;
    let card3data;

    switch (userRole) {
        case 'Admin':
            
            // title = "Laulama Registration";
            card1data = "Total Registration";
            card2data = "Pending Registration";
            card3data = "Complete Registration";

            break;

        case 'Teacher':

            // title = "Students 10-year Status";
            card1data = "Total Students";
            card2data = "Not Started";
            card3data = "Started";

            break;

        case 'Principal':

            // title = "Students 10-year Status";
            card1data = "Total Students";
            card2data = "Not Started";
            card3data = "Started";

            break;

        default:
            break;
    }
        return (
        <div className="status-card-container">
            <Card>
                <div className="card-content">
                    <div className="status-card-heading">{props.title}
                    </div>
                    <div className="grid status-cards">
                        <div className="status-card col-4">
                            <Card>
                                <div className="grid">
                                    <div className="col-1">
                                        <div className="g-line" style={{ color: "#91BF82" }}>&nbsp;
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="number">{props.totalStudents}</div>
                                        <div className="textvalue">{card1data}</div>
                                    </div>
                                    <div className="col-3">
                                        <FaUsers
                                            size="large"
                                            style={{ width: "50px", color: "#91BF82" }}
                                            className="status-card-icon"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="status-card col-4">
                            <Card>
                            <div className="grid">
                                    <div className="col-1">
                                    <div className="g-line" style={{ color: "#FC5145" }}>&nbsp;
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="number">{props.notStarted}</div>
                                        <div className="textvalue">{card2data}</div>
                                    </div>
                                    <div className="col-3">
                                        <FaHourglassStart
                                            size="large"
                                            style={{ width: "50px", color: "#FC5145" }}
                                            className="status-card-icon"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="status-card col-4">
                            <Card>
                            <div className="grid">
                                    <div className="col-1">
                                    <div className="g-line" style={{ color: "#EA8254" }}>&nbsp;
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="number">{props.studentsStarted}</div>
                                        <div className="textvalue">{card3data}</div>
                                    </div>
                                    <div className="col-3">
                                        <FaFlagCheckered
                                            size="large"
                                            style={{ width: "50px", color: "#EA8254" }}
                                            className="status-card-icon"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                </div>
            </Card>
        </div>
    );
}

export default StatusCard;
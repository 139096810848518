import React, { useState, useEffect, useRef} from "react";
import Counter from "../../../Shared-Component/Counter/Counter";
import "./ShowCase.scss";

const ShowCase = () => {

  return (
    <>
            <div className="Showcase">
                <div className="showcase-title">Laulama Showcase</div>
                <div className="showcase-items grid m-0">
                    <div className="sc-item1 col-12 md:col-6 lg:col-3">
                        <div className="item-number">
                        <Counter sectionId="counter1" minCount={0} maxCount={13} />
                        </div>
                        <div className="item-text">Years in Service</div>
                    </div>
                    <div className="sc-item2 col-12 md:col-6 lg:col-3">
                        <div className="item-number">
                        <Counter sectionId="counter1" minCount={0} maxCount={14} />
                        </div>
                        <div className="item-text">No. of Schools</div>
                    </div>
                    <div className="sc-item3 col-12 md:col-6 lg:col-3">
                        <div className="item-number">
                        <Counter sectionId="counter1" minCount={8000} maxCount={8115} />
                        </div>
                        <div className="item-text">No. of Students</div>
                    </div>
                    <div className="sc-item4 col-12 md:col-6 lg:col-3">
                        <div className="item-number">
                          <Counter sectionId="counter1" minCount={0} maxCount={19} /></div>
                        <div className="item-text">No. of Programs</div>
                    </div>
                </div>
            </div>
    </>
  );
};

export default ShowCase;

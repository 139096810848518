import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import Waitingimg from '../../Assets/Wait-teachers-ilustration.svg';
import '../../Shared-Component/PieChart/PieChart.scss';
import { Divider } from 'primereact/divider';

const PieChart = ({ schoolDetails }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [showChart, setShowChart] = useState(false);

    useEffect(() => {
        if (schoolDetails && schoolDetails.totalPendingCount > 0) {
            setShowChart(true);
            setChartData({
                labels: ['Pending Registration', 'Complete Registration'],
                datasets: [{
                    data: [schoolDetails.totalPendingCount, schoolDetails.totalCompletedCount],
                    backgroundColor: ['#3B8A6E', '#91BF82']
                }]
            });

            setChartOptions({
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            usePointStyle: true,
                            padding: 25,
                            borderWidth: 2,
                            borderColor: 'rgba(0, 0, 0, 0.2)'
                        }
                    }
                }
            });
        } else {
            setShowChart(false);
        }
    }, [schoolDetails]);

    return (
        <div className='piechart-container'>
            <div className='pie-card-section'>
                {showChart ? (
                    <div className='pie-chart-section'>
                        <div className='text-center'><b>{`${schoolDetails?.schools?.schoolName} Registration`}</b></div>
                        <Divider />
                        <div className="card flex justify-content-center align-items-center pie" >
                            <Chart
                                type="pie"
                                data={chartData}
                                options={chartOptions}
                                className="pie-chart"
                            />
                        </div>
                    </div>
                ) : (
                    <div className='p-6 wait-section'>
                        <div className='wait-img flex align-items-center justify-content-center p-3 '>
                            <img src={Waitingimg} alt='Loginimage' />
                        </div>
                        <div className='wait-content flex align-items-center justify-content-center w-auto'>
                            <p>To see information here please start adding new persona.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PieChart;


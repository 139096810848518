import React, { useState, useEffect } from "react";
import { DataView } from "primereact/dataview";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "../ViewLists/ViewCard.scss";
import { Link } from "react-router-dom";
import Groups from "../../Module/Principal/Groups/Groups";
import MyStudent from "../../Module/Teacher/MyStudent/MyStudent";

const ViewCard = ({ title, forwhom, teacherName, principalName, userRole, showSeeAllLink }) => {
    const itemsPerPage = 5;

    const [lists, setLists] = useState([]);

    useEffect(() => {
        if (userRole === "Teacher" && teacherName && teacherName.length > 0) {
            setLists(teacherName.slice(0, itemsPerPage));
        } else if (userRole === "Principal" && principalName && principalName.length > 0) {
            setLists(principalName.slice(0, itemsPerPage));
        }
    }, [teacherName, principalName, userRole]);

    const teacherItemTemplate = (list) => {
        const percentage = list.totalTenYearPlanPercentage;
        const roundedPercentage = Math.round(Number(percentage));

        return (
            <div className="viewlist-container">
                <div className="content-field">
                    {list.gender === 'Female' || list.gender === 'F' ? (
                        <Avatar
                            image={require(`../../Assets/Avatar/TeacherProfile.png`)}
                            style={{ width: 60, height: 60 }}
                            size="large"
                            shape="circle"
                        />
                    ) : list.gender === 'Male' || list.gender === 'M' ? (
                        <Avatar
                            image={require(`../../Assets/Avatar/StudentProfile.png`)}
                            style={{ width: 60, height: 60 }}
                            size="large"
                            shape="circle"
                        />
                    ) : (
                        <Avatar icon="pi pi-user" size="large" shape="circle" />
                    )
                    }

                    <div className="name-field">
                        <div className="name-list">{list.firstName}</div>
                    </div>

                    <div className="progress-bar" style={{ width: 60, height: 60 }}>
                        <CircularProgressbarWithChildren
                            value={roundedPercentage}
                            text={roundedPercentage + '%'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const principalItemTemplate = (list) => {
        const percentage = list.teacherStudentPercentage;
        const roundedPercentage = Math.round(Number(percentage));

        return (
            <div className="viewlist-container">
                <div className="content-field">
                    {list.gender === 'Female' || list.gender === 'F' ? (
                        <Avatar
                            image={require(`../../Assets/Avatar/TeacherProfile.png`)}
                            style={{ width: 60, height: 60 }}
                            size="large"
                            shape="circle"
                        />
                    ) : list.gender === 'Male' || list.gender === 'M' ? (
                        <Avatar
                            image={require(`../../Assets/Avatar/StudentProfile.png`)}
                            style={{ width: 60, height: 60 }}
                            size="large"
                            shape="circle"
                        />
                    ) : (
                        <Avatar icon="pi pi-user" size="large" shape="circle" />
                    )
                    }

                    <div className="name-field">
                        <div className="name-list">{list.firstName}</div>
                    </div>

                    <div className="progress-bar" style={{ width: 60, height: 60 }}>
                        <CircularProgressbarWithChildren
                            value={roundedPercentage}
                            text={`${roundedPercentage}%`}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = userRole === 'Teacher' ? teacherItemTemplate : principalItemTemplate;

    return (
        <Card className='h-full'>
            <div className="viewcard-section" >
                <div className="title-name">{title}</div>
                {userRole === "Teacher" ? (
                    <DataView value={lists} itemTemplate={itemTemplate} />
                ) : userRole === "Principal" ? (
                    <DataView value={lists} itemTemplate={itemTemplate} />
                ) : (
                    <div>No data available</div>
                )}
                {showSeeAllLink && (
                    <div className="load-more">
                        {forwhom === "students" ? (
                            <Link to="/teacher/studentsList" element={<MyStudent />}>
                                See all my {forwhom}
                            </Link>
                        ) : forwhom === "teachers" ? (
                            <Link to="/principal/groups" element={<Groups />}>
                                See all my {forwhom}
                            </Link>
                        ) : null}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default ViewCard;

import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Illustration from '../../../Assets/Loginimage.svg';
import '../Admin.scss';
import axios from 'axios';
import { DELETE_GROUP_URL, GET_GROUP_URL, GET_TOTAL_NUMBER_OF_USERS_URL } from '../../../Utils/AdminService';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { CONTENT_HEADERS } from '../../../Utils/StudentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';


const Group = () => {
    const userRole = 'Admin';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('groups');
    const [groupData, setGroupData] = useState([]);

    const [deleteGroupDialog, setDeleteGroupDialog] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedGroupName, setSelectedGroupName] = useState(null);

    const [schoolDetails, setSchoolDetails] = useState();
    const [name, setName] = useState();

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });

    useEffect(() => {
        fetchData();
        fetchDetails();
    }, []);

    const fetchData = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });


        axios
            .get(`${GET_GROUP_URL}`, { headers: CONTENT_HEADERS })
            .then((response) => {
                setGroupData(response.data);
                setDatass(response.data);
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const fetchDetails = () => {
        const schoolId = localStorage.getItem('schoolId');
        axios
            .get(GET_TOTAL_NUMBER_OF_USERS_URL + schoolId, { headers: CONTENT_HEADERS })
            .then((response) => {
                localStorage.setItem('schoolId', response.data.schools.schoolId);
                setSchoolDetails(response.data);
                setName(`${response.data.userDetails.firstName} ${response.data.userDetails.lastName}`);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            })
            .catch((error) => {
                setLoading(true);
                console.log(error);
            });
    }

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
        switch (item) {
            case 'dashboard':
                navigate('/admin/dashboard');
                break;
            case 'principals':
                navigate('/admin/principals');
                break;
            case 'teachers':
                navigate('/admin/teachers');
                break;
            case 'groups':
                navigate('/admin/groups');
                break;
            case 'students':
                navigate('/admin/students');
                break;
            default:
                break;
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button rounded outlined className="action-button" onClick={() => handleEdit(rowData)}>
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
                <Button rounded outlined severity="danger" onClick={() => handleDelete(rowData)} className="action-button">
                    <FontAwesomeIcon icon={faTrashCan} />
                </Button>
            </React.Fragment>
        );
    };

    const handleEdit = (rowData) => {
        navigate('/admin/groups/create', { state: { rowData } });
    }

    const handleDelete = (selectedGroup) => {
        setSelectedGroupId(selectedGroup.groupId);
        setSelectedGroupName(selectedGroup.groupName);
        setDeleteGroupDialog(true);
    };


    const hidedeleteGroupDialog = () => {
        setDeleteGroupDialog(false);
    };

    const deleteGroup = () => {
        setLoading(true);
        if (selectedGroupId) {
            axios
                .delete(DELETE_GROUP_URL + selectedGroupId, { headers: CONTENT_HEADERS })
                .then((res) => {
                    setDeleteGroupDialog(false);
                    fetchData();
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Delete API Error:', error);
                });
        } else {
            console.error('Invalid data for deletion.');
        }
    };

    const deleteGroupDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={hidedeleteGroupDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteGroup} />
        </React.Fragment>
    );

    const handleAddNewClick = () => {
        navigate("/admin/groups/create");
    };

    const rightToolbarTemplate = () => {
        return (
            <Button
                label="Add New"
                icon="pi pi-plus"
                className="p-button-help"
                onClick={handleAddNewClick}
            />
        );
    };

    const [searchQuery, setSearchQuery] = useState('');

    const [datass, setDatass] = useState([]);

    const [displayedTeacherss, setDisplayedTeachers] = useState([]);

    const overlayPanelRef = useRef(null);

    const [overlayPanelVisible, setOverlayPanelVisible] = useState(false);

    const showListofTeachers = (event, rowData) => {
        const teachers = rowData.assignedTeachers || [];
        const nonEmptyTeachers = teachers.filter(teacher => teacher.firstName !== null);

        setDisplayedTeachers(nonEmptyTeachers);
        overlayPanelRef.current.toggle(event);
        setOverlayPanelVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const hideOverlayPanel = () => {
        overlayPanelRef.current.hide();
        setOverlayPanelVisible(false);
        document.body.style.overflow = '';
    };

    const renderAvatar = (gender) => {
        if (gender === 'Male' || gender === 'M') {
            return (
                <Avatar
                    image={require(`../../../Assets/Avatar/StudentProfile.png`)}
                    style={{ width: 30, height: 30 }}
                    size="large"
                    shape="circle"
                />
            );
        } else if (gender === 'Female' || gender === 'F') {
            return (
                <Avatar
                    image={require(`../../../Assets/Avatar/TeacherProfile.png`)}
                    style={{ width: 30, height: 30 }}
                    size="large"
                    shape="circle"
                />
            );
        } else {
            return (
                <Avatar
                    icon="pi pi-user"
                    size="mid"
                    shape="circle"
                />
            );
        }
    };

    return (
        <div className='admin-groups-container'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />

            <div className="flex justify-content-between align-items-center mx-6 admin-section">
                <h2>Groups</h2>

                <div className="flex gap-3">
                    <Button
                        label="Add New"
                        icon="pi pi-plus"
                        className="p-button-help"
                        onClick={handleAddNewClick}
                    />
                    <span className="p-input-icon-right">
                        <div className="flex align-items-center justify-content-end">
                            <InputText placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            <i className="pi pi-search" style={{position:"absolute", marginRight:"20px"}} />
                            </div>
                    </span>
                </div>
            </div>
            <div className="admin-groups-container p-d-flex p-flex-wrap mx-6 my-4">
                {/* <Card className="  align-items-end w-full">

                <Toolbar
                    className="p-0"
                    right={rightToolbarTemplate}
                ></Toolbar> */}
                <hr className='my-5' />
                {groupData.length === 0 ? (
                    <Card>
                        <div className="group-img-section flex flex-column justify-content-center align-items-center ">
                            <Image src={Illustration} alt="Image" width="350px" />
                            <Button
                                label="+ Add New"
                                className="m-3"
                                onClick={handleAddNewClick}
                            />
                            <p>To see information here, please start adding a new group</p>
                        </div>
                    </Card>
                ) : (
                    <Card>
                        <div className="datatable-card-sections">
                            <DataTable
                                value={datass.filter((list) =>
                                    (list.groupName?.toLowerCase().includes(searchQuery?.toLowerCase() || '')) ||
                                    (list.grade?.toLowerCase().includes(searchQuery?.toLowerCase() || '')) ||
                                    (list.assignedTeachers?.some(teacher => teacher.firstName.toLowerCase().includes(searchQuery?.toLowerCase() || '')))
                                )}
                                stripedRows
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="groupName" header="Group Name"
                                    className='show-group'
                                    style={{
                                        textAlign: "left",
                                    }}
                                />
                                <Column field="grade" header="Student's Grade" />
                                <Column field="graduationYear" header="Graduation Year" />
                                <Column field="assignedTeacher"
                                    header="Assigned Teacher"
                                    body={
                                        rowData => {
                                            const teachers = rowData.assignedTeachers || [];
                                            const nonEmptyTeachers = teachers.filter((teacher) => teacher.firstName !== null);

                                            const maxNamesToShow = 1;
                                            let displayedTeachers = [];

                                            if (nonEmptyTeachers.length > 0) {
                                                displayedTeachers.push(
                                                    <React.Fragment key={nonEmptyTeachers[0].id}>
                                                        {renderAvatar(nonEmptyTeachers[0].gender)}
                                                        <span>{nonEmptyTeachers[0].firstName}</span>
                                                    </React.Fragment>
                                                );
                                            }

                                            if (nonEmptyTeachers.length > maxNamesToShow) {
                                                displayedTeachers.push(<span key="ellipsis">...</span>);
                                            }

                                            return (
                                                <span
                                                    onClick={(e) => showListofTeachers(e, rowData)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        placeContent: 'start',
                                                        display: "flex",
                                                        paddingLeft: '10px',
                                                        gap: "10px"
                                                    }}>
                                                    {displayedTeachers}
                                                </span>
                                            );
                                        }
                                    }
                                />
                                <Column field="studentCount" header="Students" />
                                <Column header="Actions" body={actionBodyTemplate} exportable={false}></Column>
                            </DataTable>
                            <OverlayPanel
                                ref={overlayPanelRef}
                                appendTo={document.body}
                                onHide={hideOverlayPanel}
                            >
                                <ul className="profile-menu-list">
                                    {displayedTeacherss.map((teacher, index) => (
                                        <li key={index}>
                                            {renderAvatar(teacher.gender)}
                                            <span>{teacher.firstName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </OverlayPanel>
                        </div>
                    </Card>
                )}
                {/* </Card> */}
            </div>

            <Dialog visible={deleteGroupDialog} style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Confirm" modal footer={deleteGroupDialogFooter}
                onHide={hidedeleteGroupDialog}
            >
                <div className="confirmation-content flex">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1.5rem' }} />
                    {selectedGroupName && (
                        <span>
                            Are you sure you want to delete <b>{selectedGroupName}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

        </div >
    );
};

export default Group;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import '../../../Dashboard/MobileStudentForm/MobileStudentForm.scss';
import NavBar from '../../../../../Shared-Component/StackNavBar/StackNavBar';
import { Button } from 'primereact/button';
import AcademicPathwaysForm from '../../StudentForm/Academic-Pathways-Form/Academic-Pathways-Form';



const MobileAcademicPathwaysForm = () => {

    const userRole = 'Student';
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

    };



    return (
        <div className="mob-academypath-section">

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='m-5 '>
                <div className='mob-academypath-link' >
                    <Link to="/student/dashboard"> <FaChevronLeft /> Back To Dashboard</Link>
                </div>
                {/* <div className='mob-academypath-heading'>
                    <h3>My 10 year Academy and Career Plan</h3>
                </div> */}
                <div className='mob-academypath-button mt-3' >
                    <Button className='Button'>4. Academy Pathway</Button>
                </div>
                <AcademicPathwaysForm></AcademicPathwaysForm>
            </div>

        </div>
    );
};

export default MobileAcademicPathwaysForm;

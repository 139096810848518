import React, { useState } from 'react';
import "./Laulamafounder.scss";
import FounderImg from '../../../Assets/AboutUs/CEO-Image.jpg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Laulamafounder = () => {

    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(false);

    const toggleReadMore = () => {
        navigate('/aboutus#Founder');
    };

    return (
        <div id='Founder' className='founder-section'>
            <div className='p-5 flex justify-content-center align-items-center founder'>
                <div className='p-5 founder-img'>
                    <img src={FounderImg} alt='' />
                </div>
                <div className='p-5'>
                    <div className='founder-details-section'>
                        <p>Mel Horikami </p>
                        <span>Laulama CEO</span>
                    </div>
                    <div className='founder-para'>
                        <p> Over the course of Mel Horikami’s career in the telecommunications industry, he developed a track record of successfully tackling tough and complex problems. Mel’s ability to address and find solutions to such challenging issues was grounded in the unique perspective and set of skills acquired throughout his unconventional career path. Mel began his telecom career in an entry level field position and worked his way up through positions spanning a diverse array of functional areas across regions of the U.S. from Hawaii to New York. While working, he earned both undergraduate and graduate college degrees and ended his 30-year career in executive level positions in a Fortune 500 company. 
                        </p>
                        <Link to="/aboutus#Founder" className='link' style={{ color: '#81BEF6', fontSize: '18px' }}>Read More</Link>


                        {/* {showMore && (
                            <>
                                <span> Having been raised in a neighborhood of many socio-economic disadvantaged families and educated in the public school system, Mel decided the best way to give back to the community was through a focus on the education system.
                                    If he was able to codify and convey the knowledge and skills that he learned so schools could gain the capability to sort through and overcome their challenges, then they could become self-sustaining and serve as a hub for educational transformation across the state and beyond. This would be something that schools could continue to employ and evolve long after Mel’s work was completed.
                                </span>

                            </>
                        )}
                        {showMore ? (
                            <Link onClick={toggleReadMore} className='link' style={{ color: '#81BEF6', fontSize: '18px', paddingLeft: '10px' }}>
                                Read less
                            </Link>
                        ) : (
                            <Link onClick={toggleReadMore} className='link' style={{ color: '#81BEF6', fontSize: '18px', paddingLeft: '10px' }}>
                                Read more
                            </Link>
                        )} */}


                    </div>
                </div>
            </div>


        </div>
    )
}

export default Laulamafounder

import React from 'react'
import "./Home.scss";
import BrighterFuture from './BrighterFuture/BrighterFuture';
import FooterSection from './laulama-footer-section/FooterSection';
import Acheivements from './LaulamaAcheivements/Acheivements';
import LaulamaBanner from './Laulama-banner/LaulamaBanner';
import LaulamaValues from './Laulama-values/LaulamaValues';
import LaulamaVision from './Laulama-vision/LaulamaVision';
import LaulamaHeader from './LaulamaHeader/LaulamaHeader';
import Products_Services from './Products & Services/Products-Services';
import ShowCase from './Laulama-showcase/ShowCase';
import Testimonials from './Testimonials/Testimonials';
import NewHeader from './NewHeader/NewHeader';

const Home = () => {
  return (
    <div>
      <NewHeader/>
      {/* <LaulamaHeader/> */}
      <LaulamaBanner/>
      <Products_Services/>
      <ShowCase/>
      <Testimonials/>
      <FooterSection/>
    </div>
  )
}

export default Home







import React, { useEffect, useState } from 'react';
import '../Help.scss';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFilePdf, faFilePen, faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { LuAtom } from 'react-icons/lu';
import SubCategory from '../Category/Category';
import NavBar from '../../StackNavBar/StackNavBar';

const PersonalStatement = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [userRole, setUserRoles] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Personal Statement');
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };
    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/admin')) {
            handleAdminMenuClick(activeMenuItem);
            setUserRoles("Admin")
        } else if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRoles("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRoles("Teacher")
        } else if (currentUrl.includes('/student')) {
            handleStudentMenuClick(activeMenuItem);
            setUserRoles("Student")
        }

    }, [activeMenuItem]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handleAdminMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const handleStudentMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/student/dashboard');
                break;
            case "profile":
                navigate("/student/profile");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='help-section'>
                <div className='help-heading'>
                    <h3>Help Section</h3>
                </div>
                <div className='help-sub-section '>
                    <div className='m-5 flex flex-wrap'>
                        <div className='col-3 category-section border-right-3'>
                            <h3 className='mb-6'><b>By Category</b></h3>
                            <SubCategory selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick} />
                        </div>
                        <div className='col-9 guide-section-details pl-5'>
                            {selectedCategory === 'Personal Statement' && (
                                <div className='personal'>
                                    <h2 className='mb-6'>Personal Statement</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFilePen} className='filepen' /><h3>Personal Statement Paragraph</h3>
                                            </div>
                                            <div className='heading p-1'>Your opportunity to share your unique story and aspirations.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} /> 
                                                <a href="https://staticfile.laulamaschools.com/Personal+Statement+Paragraph+Frame.pdf" target="_blank" download>Download PDF</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom' /><h3>You Science</h3>
                                            </div>
                                            <div className='heading p-1'>Video of how to LOG IN TO you science ACCOUNT</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /> 
                                                <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/you-science" target="_blank">External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom-two' /><h3>Video: You Science</h3>
                                            </div>
                                            <div className='heading p-1'>Summit Results Walkthrough</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                                <a href="https://vimeo.com/347806606" target="_blank">Open Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalStatement;

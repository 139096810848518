import React from 'react';
import { Chip } from 'primereact/chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';

const FilterChips = ({ selectedFilters, onRemoveFilter, handleClearAllFilters }) => {
    return (
        <div className='flex'>
            <p className='m-0 flex align-items-center mr-2'>Filtered by: </p>
            {selectedFilters.map((filter, index) => (
                <>
                    <Chip
                        key={index}
                        label={filter}
                        // removable
                        // onRemove={() => onRemoveFilter(filter)}
                        readOnly
                    // removeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                    />


                </>
            ))}

            {
                selectedFilters.length > 0 && (
                    <Button className="clear-button" onClick={handleClearAllFilters} >Clear All <FontAwesomeIcon icon={faTimesCircle} className='ml-2' /></Button>
                )
            }
        </div>
    );
};

export default FilterChips;

import React from "react";
import "./profileCard.scss";
import { Avatar } from 'primereact/avatar';
import { FaBook, FaIdCard, FaChalkboardTeacher, FaGraduationCap } from 'react-icons/fa';
import { Card } from 'primereact/card';
import 'primeicons/primeicons.css';
import maleAvatar from "../../Assets/Avatar/StudentProfile.png";
import femaleAvatar from "../../Assets/Avatar/TeacherProfile.png";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";

const ProfileCard = (props) => {
  const userRole = props.userRole;
  const gender = props.gender;
  const gpa = props.highSchoolGap;
  let roleDescription;

  const location = useLocation();
  const currentPath = location.pathname;

  const getAvatarForGender = () => {
    if (gender === "Male" || gender === "M") {
      return <img src={maleAvatar} alt="Male Avatar"/>;
    } else if (gender === "Female" || gender === "F") {
      return <img src={femaleAvatar} alt="Female Avatar" />;
    } else {
      return <Avatar icon="pi pi-user" size="xlarge" shape="circle" />;
    }
  };

  switch (userRole) {
    case 'Admin':
        roleDescription = 'Administrator High School';
        break;
    case 'Teacher':
      roleDescription = 'Personal Pathway plan Teacher';
        break;
    case 'Principal':
      roleDescription = 'High School Principal';
        break;
    default:
        break;
}

  return (
    <div className="profilecard-container h-full">
      <Card className="profile-card h-full p-0">
        {userRole === "Student" && !(currentPath.includes("teacher") || currentPath.includes("principal")) && (
          <div>
            <div className="profile-card-top grid justify-content-center align-items-center">
              <div className="avatar  col-5">
                {getAvatarForGender()}
              </div>
              <div className="name-id col-7">
                <div className="profile-student-name">{props.name}</div>
                <div className="school-id">
                  <div>
                    <FaIdCard
                      size="large"
                      style={{ width: "30px", color: "#3B8A6E" }}
                      className="icon-card"
                    />
                  </div>
                  <div className="id">ID:{props.schoolId}</div>
                </div>
                <div className="grade-section">
                  <div>
                    <FaBook
                      className="icon-book"
                      size="large"
                      style={{ width: "25px", color: "#3B8A6E" }}
                    />
                  </div>
                  <div className="sub-text">{props.grade}</div>
                </div>
                <div className="year-section">
                  <div>
                    <FaGraduationCap
                      className="icon-user"
                      size="large"
                      style={{ width: "35px", color: "#3B8A6E" }}
                    />
                  </div>
                  <div className="id">GPA:{props.gpa || "-"}</div>
                </div>
              </div>
              <div className="profile-link">
                <Button className="no-bg-btn">
                  <a href="/student/profile">View Profile</a>
                </Button>
              </div>
            </div>
          </div>
        )}

        {userRole === "Student" && (currentPath.includes("teacher") || currentPath.includes("principal")) && (
          <div>
            <div className="profile-card-top grid justify-content-center align-items-center">
              <div className="avatar  col-5">
                {getAvatarForGender()}
              </div>
              <div className="name-id col-7">
                <div className="profile-student-name">{props.name}</div>
                <div className="school-id">
                  <div>
                    <FaIdCard
                      size="large"
                      style={{ width: "30px", color: "#3B8A6E" }}
                      className="icon-card"
                    />
                  </div>
                  <div className="id">ID:{props.schoolId}</div>
                </div>
                <div className="grade-section">
                  <div>
                    <FaBook
                      className="icon-book"
                      size="large"
                      style={{ width: "25px", color: "#3B8A6E" }}
                    />
                  </div>
                  <div className="sub-text">{props.grade}</div>
                </div>
                <div className="year-section">
                  <div>
                    <FaGraduationCap
                      className="icon-user"
                      size="large"
                      style={{ width: "35px", color: "#3B8A6E" }}
                    />
                  </div>
                  <div className="id">GPA:{props.gpa || "-"}</div>
                </div>
              </div>
              <div className="profile-link">
                <Button className="no-bg-btn">
                  <a href={'/' + sessionStorage?.userType + '/profile'}>View Profile</a>
                </Button>
              </div>
            </div>
          </div>
        )}

        {((userRole === 'Admin') || (userRole === 'Teacher') || (userRole === 'Principal')) && (
          <div className="not-student">
            <div className="profile-card-top grid">
              <div className="avatar">
                {getAvatarForGender()} 
              </div>
              <div className="name-id">
                <div className="profile-name">{props.name}</div>
                <div className="school-id">
                  <div>
                    <FaChalkboardTeacher
                      size="large"
                      style={{ width: "40px", color: "#EA8254" }}
                    />
                  </div>
                  <div className="id">{props.roleDescription}</div>
                </div>
              </div>
            </div>
          </div>
        )}

      </Card>
    </div>
  );
};

export default ProfileCard;

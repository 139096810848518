import React, { useEffect, useState } from 'react'
import NewHeader from '../Home/NewHeader/NewHeader';
import './System.scss'
import Image from "./../../Assets/System/System-Image.png"
import { Button } from 'primereact/button';
import schoolIcon from './../../Assets/System/school-icon.svg';
import schoolImage from './../../Assets/System/school-img.jpg';
import banner from './../../Assets/System/banner-network.jpg';
import { useLocation } from 'react-router-dom';
import FooterSection from '../Home/laulama-footer-section/FooterSection';
import Assess from "../../Assets/System/Assess.png";
import Design from "../../Assets/System/Design.png";
import Enable from "../../Assets/System/Enable.png";
import Perform from "../../Assets/System/Perform.png";


const System = () => {
    const location = useLocation();
    const [showContent, setShowContent] = useState(false);
    const [hoveredItem, setHoveredItem] = useState("assess");

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem("assess");
    };

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
            if (hash === '#laulamaSchoolsNetwork') {
                setShowContent(true)
            } else {
                setShowContent(false);
            }
        } else {
            setShowContent(false);
        }
    }, [location.hash]);


    const listOfSchools = [
        {
            schoolname: 'Koloa Elementary School',
            principalname: 'Leila Maeda-Kobayashi',
            content: 'Is a public school located on the leeward, sunny south shore of Kaua`i. Köloa is situated in an historic plantation community. As the oldest public school on Kaua`i, established in 1877, their educational philosophy revolves around the mantra:  “Educate, Empower, and Engage Everyone Everyday”',
            schoolUrl: 'https://www.koloaes.k12.hi.us/home',
            imageUrl: schoolImage
        },
        {
            schoolname: 'Ele’ele Elementary School',
            principalname: 'Allison Carveiro',
            content: 'Is a public school located in Eleele, Hawaii. The school community is committed to providing a nurturing, supportive, and standards-based education that guides all students toward a life of continuous learning.',
            schoolUrl: 'https://www.eleeleschool.k12.hi.us/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Elsie H. Wilcox Elementary School',
            principalname: 'Corey Nakamura',
            content: "Is a public school located in Lihue, Hawaii. The school is committed to empowering students for life-long success. Their educational approach emphasizes character development through nine Hawaiian values: Aloha, Ho’oponopono, 'Ike, Kōkua, Kuleana, Laulima, Lōkahi, Mālama, and 'Ohana'",
            schoolUrl: 'https://www.wilcox.k12.hi.us/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Chiefess Kamakahelei Middle School',
            principalname: 'Jean Morris',
            content: "Is a public school located in the charming town of Lihue, Hawaii, is a public middle school that serves students in grades six through eight.  Their mission is to cultivate well-rounded lifelong learners and productive citizens. The school values character development and emphasizes Hawaiian values such as Aloha, Ho’oponopono, 'Ike, Kōkua, Kuleana, Laulima, Lōkahi, Mālama, and 'Ohana'",
            schoolUrl: 'https://www.ckms.k12.hi.us/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Kauaʻi High School',
            principalname: 'Marlene Leary',
            content: 'Located in Lihue, Hawaii, is a public high school named after the beautiful island of Kauaʻi. Since its inception in 1914, as the first public high school on the island of Kauaʻi, it boasts a rich history, diverse programs, and numerous notable alumni.  It’s vision is creating and sustaining a culture the Educates, Engages, and Empowers ALL to be successful.',
            schoolUrl: 'https://www.kauaihigh.org/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Kapa’a Elementary School',
            principalname: 'Salynn Gonsalves',
            content: 'Is a public school located in the town of Kapa’a on the island of Kaua’i.  They prioritize creating a positive and nurturing environment for all students and their mission is to educate, empower, and engage everyone every day.',
            schoolUrl: 'https://www.kapaaes.k12.hi.us/home',
            imageUrl: schoolImage
        }, {
            schoolname: 'Kapa’a High School',
            principalname: 'Thomas Cox',
            content: "Is a public schools located in the town of Kapa’a on the island of Kaua’I, is a public high school that emphasize character development and uphold Hawaiian values such as Aloha, Ho’oponopono, 'Ike, Kōkua, Kuleana, Laulima, Lōkahi, Mālama, and 'Ohana. They prepare all students for college, career, and citizenship in the 21st century, and they offer a variety of programs and opportunities to achieve this goal.",
            schoolUrl: 'https://kapaahighschool.net/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Kaʻaʻawa Elementary School',
            principalname: 'Luke Payne',
            content: 'Is a public school that was established in 1904 on land donated by the Swanzy family.  It serves the communities of Kualoa, Kaʻaʻawa, Kahana, and Punaluʻu.  Location on tbe island of O’ahu, on the Windward side, they are a “Leader in Me” school and their vision is that their students will leave a legacy by leading with greatness.',
            schoolUrl: 'https://www.kaaawaschool.k12.hi.us/home',
            imageUrl: schoolImage
        }, {
            schoolname: 'Waimalu Elementary School',
            principalname: 'Ronnell Nipp',
            content: 'Is a public school located in Aiea, Hawaii. Their vision is Empower and Nuture successful leaders and learners.',
            schoolUrl: 'https://www.waimaluelementary.org/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Ala Wai Elementary School',
            principalname: 'Michelle DeBusca',
            content: 'Is a public elementary school located in Honolulu, Hawaii. Established in 1954, their mission is to prepare students to achieve high standards in a safe, positive, and nurturing environment.',
            schoolUrl: 'https://www.alawaielementary.org/',
            imageUrl: schoolImage
        }, {
            schoolname: 'Princess Ruth Keʻelikolani Middle School',
            principalname: 'Joseph Passantino',
            content: '(formerly known as Central Grammar School, Central Intermediate School, and Central Middle School) is a public school in Honolulu, Hawaii. It occupies a historic building on the grounds of Keōua Hale, the former palace of Princess Ruth Keʻelikolani.  Their mission is Nuture, Inspire and Empower all students to become resilient learners.',
            schoolUrl: 'https://www.keelikolanimiddle.org/',
            imageUrl: schoolImage
        },
    ];
    const [schools, setSchools] = useState(listOfSchools);
    const [currentPage, setCurrentPage] = useState(1);
    const schoolsPerPage = 5;

    const totalPages = Math.ceil(listOfSchools.length / schoolsPerPage);

    const indexOfLastSchool = currentPage * schoolsPerPage;
    const indexOfFirstSchool = indexOfLastSchool - schoolsPerPage;
    const currentSchools = listOfSchools.slice(indexOfFirstSchool, indexOfLastSchool);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    return (
        <>
            <NewHeader />
            {showContent ? (
                <>
                    <div id="laulamaSchoolsNetwork" className="network-banner">
                        <section className="showcase">
                            <div className="network-bg">
                                <img src={banner} alt='Bannerimage' />
                            </div>

                            <div className=" overlay priority-sections grid m-0 p-0">
                                <div className="col-12 md:col-6 lg:col-6 p-0 m-0 border-noround">
                                    <div className=" text-center p-4 h-full">
                                        <h2 className="position">Laulama Schools Network</h2>
                                        <span>
                                            Accelerating and scaling improvement learning and results.
                                        </span>
                                    </div>
                                </div>

                                <div className="count-school col-12 md:col-6 p-0 m-0 border-noround">
                                    <div className="listSchool-section text-center p-4 h-full">
                                        <img src={schoolIcon} alt="" />

                                        <div className="text-para">
                                            <p><strong>14</strong></p>
                                            <p>No. of schools</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div className="school-list-section">
                        <div className=' m-6'>
                            <h2>List of Schools & Contacts</h2>
                            {currentSchools.map(schools => (
                                <div className='school-list'>
                                    <div className="" key={schools.schoolname} >
                                        <div className="school-main-section">
                                            <div className="school-image-section">
                                                <img src={schoolImage} width={300} />
                                            </div>

                                            <div className="school-content-section">
                                                <div className=' school-details'>
                                                    <div className="schoolname"><b>{schools.schoolname}</b></div>
                                                    <div className="principalname">Principal : {schools.principalname}</div>
                                                    <div className='button'><a href={schools.schoolUrl} target='_blank'><Button>Go to the site</Button></a></div>
                                                </div>
                                                <div className="content"><b>Description :</b> <div>{schools.content}</div></div>
                                                <div className="schoolUrl">
                                                    Website: <a href={schools.schoolUrl} target='_blank'>{schools.schoolUrl}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pagination m-5">
                            <div onClick={prevPage} className={`prev ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage === 1}>{'<'}</div>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <div key={i + 1} onClick={() => paginate(i + 1)} className={`page ${currentPage === i + 1 ? 'active' : ''}`}>
                                    {i + 1}
                                </div>
                            ))}
                            <div onClick={nextPage} className={`next ${currentPage === totalPages ? 'disabled' : ''}`} disabled={currentPage === totalPages}>{'>'}</div>
                        </div>

                    </div>
                </>
            ) : (
                <>
                    <div id="improvementInnovationPlatform" className="improvement-section">
                        <h2 style={{ width: "fit-content", borderBottom: "3px solid #91BF82" }}>
                            ADE<span className='p-color'>P</span>t Continuous Improvement Process</h2>
                        <p>The Platform is comprised of a continuous improvement process that is designed for Systems Thinking called ADE
                            <span style={{ fontSize: "20px", color: "red" }}>P</span>t. Within the ADE<span style={{ fontSize: "20px", color: "red" }}>P</span>t framework, there are various widely-recognized improvement tools that have been adapted for education.</p>

                    </div>

                    <div className="system-banner-section">
                        <div className={`item ${hoveredItem === "assess" ? 'hovered' : ''}`} data-order="1" onMouseEnter={() => handleMouseEnter("assess")} onMouseLeave={handleMouseLeave}>

                            {hoveredItem === "assess" ? (
                                <div className="content">
                                    <h2>Assess</h2>
                                    <p>Determining why the phenomena’s design is producing the results that it is.</p>
                                </div>
                            ) :
                                <div className="default" style={{ backgroundColor: "rgba(234, 130, 84, 0.85)", height: "100%" }}>
                                    <h2 className='default-label'>Assess</h2>
                                </div>
                            }
                        </div>

                        <div className={`item ${hoveredItem === "design" ? 'hovered' : ''}`} data-order="2" onMouseEnter={() => handleMouseEnter("design")} onMouseLeave={handleMouseLeave}>
                            {hoveredItem === "design" ? (
                                <div className="content">
                                    <h2>Design</h2>
                                    <p>Modeling a phenomena to understand how it is designed for the outcomes it is producing and/or intended to produce.</p>
                                </div>
                            ) :
                                <div className="default" style={{ backgroundColor: "rgba(59, 138, 110, 0.50)", height: "100%" }}>
                                    <h2 className='default-label'>Design</h2>
                                </div>
                            }
                        </div>

                        <div className={`item ${hoveredItem === "enable" ? 'hovered' : ''}`} data-order="3" onMouseEnter={() => handleMouseEnter("enable")} onMouseLeave={handleMouseLeave} >
                            {hoveredItem === "enable" ? (
                                <div className="content">

                                    <h2>Enable</h2>
                                    <p>Identifying enablers to improve or change the design’s outcomes.</p>
                                </div>
                            ) :
                                <div className="default" style={{ backgroundColor: "rgba(5, 41, 65, 0.50)", height: "100%" }}>
                                    <h2 className='default-label'>Enable</h2>
                                </div>
                            }
                        </div>

                        <div className={`item ${hoveredItem === "perform" ? 'hovered' : ''}`} data-order="4" onMouseEnter={() => handleMouseEnter("perform")} onMouseLeave={handleMouseLeave} >
                            {hoveredItem === "perform" ? (
                                <div className="content">

                                    <h2>Perform</h2>
                                    <p>Executing the design and/or change enablers for desired outcomes.</p>
                                </div>
                            ) :
                                <div className="default" style={{ backgroundColor: "rgba(5, 41, 65, 0.50)", height: "100%" }}>
                                    <h2 className='default-label'>Perform</h2>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="mob-system-banner-section" style={{ paddingTop: "0" }}>
                        <div className="content">
                            <div className="overlay" style={{ background: "rgba(234, 130, 84, 0.85)" }}>
                                <h2>Assess</h2>
                                <p>Determining why the phenomena’s design is producing the results that it is.</p>
                            </div>
                            <img src={Assess} alt="OnsiteImage" className="image" />
                        </div>

                        <div className="content">
                            <div className="overlay" style={{ background: "rgba(59, 138, 110, 0.50)" }}>
                                <h2>Design</h2>
                                <p>Modeling a phenomena to understand how it is designed for the outcomes it is producing and/or intended to produce.</p>
                            </div>
                            <img src={Design} alt="TherapyImage" className="image" />
                        </div>

                        <div className="content">
                            <div className="overlay" style={{ background: "rgba(5, 41, 65, 0.50)" }}>
                                <h2>Enable</h2>
                                <p>Identifying enablers to improve or change the design’s outcomes.</p>
                            </div>
                            <img src={Enable} alt="FamilyImage" className="image" />
                        </div>

                        <div className="content">
                            <div className="overlay" style={{ background: "rgba(5, 41, 65, 0.50)" }}>
                                <h2>Perform</h2>
                                <p>Executing the design and/or change enablers for desired outcomes.</p>
                            </div>
                            <img src={Perform} alt="FamilyImage" className="image" />
                        </div>
                    </div>

                    <div id="improvementTools" className="improvement-tools">
                        <h2 style={{ width: "fit-content", borderBottom: "3px solid #91BF82" }}>Improvement tools</h2>
                        <p>We adapt universally recognized improvement concepts and methods for education.    These customized improvement tools are used in the ADE<span style={{ fontSize: "20px", color: "red" }}>P</span>t continuous improvement process to optimize, redesign and transform a school’s design.  As a school moves through this improvement continuum, they begin to produce breakthrough results.</p>
                    </div>

                    <div id='changeExperimentsAndInitiatives' className="grid changeExperimentsAndInitiatives">

                        <div className="col-12 lg:col-6">
                            <div className="image-section-left text-center h-full">
                                <img src={Image} alt="Logo" />
                            </div>
                        </div>

                        <div className="col-12 lg:col-6">
                            <div className="content-section2">
                                <h2 style={{ width: "fit-content", borderBottom: "3px solid #fc5145" }}>Change Experiments & Initiative</h2>

                                <span>
                                    The Change Package was adopted from the Carnegie Foundation
                                    for the Advancement of Teaching. It is a document that conveys
                                    knowledge of how to achieve positive changes. It could be about
                                    some evidence-based idea that was successfully applied at a
                                    school. Or, it could be a case study of a school’s idea that produced
                                    improvement. In either situation, Change Packages serve as a
                                    starting point for improvement experiments and initiatives at
                                    schools. It is used to accelerate and scale improvement learning
                                    and results across the Laulama Schools Network.
                                </span>
                            </div>
                        </div>

                    </div >

                    <div id="priorityAreas" className="priority-section">
                        <h2 style={{ width: "fit-content", borderBottom: "3px solid #91BF82" }}>Priority Areas</h2>
                        <p>
                            Schools learn how to identify priority areas.  Once selected, the ADE<span style={{ fontSize: "20px", color: "red" }}>P</span>t continuous improvement process and tools are used to address the priorities, and concurrently transform the organization’s improvement culture and capability.
                        </p>
                    </div>

                    {/* <div className="priority-sections grid m-0 p-0">
                        <div className="col-12 md:col-6 lg:col-6 p-0 m-0 border-noround">
                            <div className="content-section1 text-center p-4 h-full">
                                <h2 className="position">Modeling</h2>
                                <span>
                                    Praesent vel ultrices enim. Aenean bibendum aliquet augue, sit amet lacinia lacus pellentesque non.
                                    Pellentesque finibus lectus dui, vitae bibendum ante commodo vel.
                                </span>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 lg:col-6 p-0 m-0 border-noround">
                            <div className="content-section2 text-center p-4 h-full">
                                <h2 className="position">Data Analytics </h2>
                                <span>
                                    Praesent vel ultrices enim. Aenean bibendum aliquet augue, sit amet lacinia lacus pellentesque non.
                                    Pellentesque finibus lectus dui, vitae bibendum ante commodo vel.
                                </span>
                            </div>
                        </div>
                    </div> */}

                    <div id='redesignAndInnovation' className="redesign-section">
                        <h2 style={{ width: "fit-content", borderBottom: "3px solid #91BF82" }}>Redesign & Innovations</h2>
                        <p>When a school’s design is performing at its full capacity, redesign and innovation are required to elevate the capacity level. Once determined, we work with schools to formulate and implement an approach.</p>
                    </div>

                    {/* <div className="m-6 virtual-section">
                        <h2 style={{ width: "fit-content", borderBottom: "3px solid #91BF82" }}>Virtual Teaching & Learning</h2>
                        <p></p>
                    </div> */}
                </>
            )}

            <FooterSection />

        </>
    )
}

export default System;
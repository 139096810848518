import React, { useState, useEffect } from "react";
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Panel } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./HighSchoolGRsection.scss"

const HighSchoolGRsection = ({ getApiResponse }) => {

    const [showGraduation, setShowGraduation] = useState(window.innerWidth <= 1008);
    const [showMore, setShowMore] = useState(false);
    const [allZero, setAllZero] = useState();

    useEffect(() => {
    }, [showMore]);
    const toggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    useEffect(() => {
        const handleResize = () => {
            setShowGraduation(window.innerWidth <= 1008);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [creditsCompleted, setCreditsCompleted] = useState(() => getApiResponse?.creditsCompleted);
    const [creditsPending, setCreditsPending] = useState(() => getApiResponse?.creditsPending);
    const [totalGraduationCredits, setTotalGraduationCredits] = useState(() => getApiResponse?.totalGraduationCredits);


    const dataTableData = Object.keys(getApiResponse?.pendingCredits).map(area => ({
        area: area,
        pendingCredits: getApiResponse.pendingCredits[area],
        completedCredits: getApiResponse.completedCredit[area],
        totalCredits: getApiResponse.pendingCredits[area] + getApiResponse.completedCredit[area],
        newValue: getApiResponse.completedCredit[area] + "/" + (getApiResponse.pendingCredits[area] + getApiResponse.completedCredit[area])
    }));

    useEffect(() => {
        const allValuesZero = dataTableData.every(data => {
            return data.pendingCredits === 0 &&
                data.completedCredits === 0 &&
                data.totalCredits === 0;
        });

        setAllZero(allValuesZero);
    }, [dataTableData]);

    return (

        <div className="Student-GR-card-container">
            <>
                {showGraduation ? (
                    <div>
                        <div className="GR-Tabview">
                            <div className="gr-section">
                                <div className="circular-progress-bar" style={{ width: 300, }}>
                                    <CircularProgressbarWithChildren value={creditsCompleted}>
                                        <div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompleted}</span> /24</div>
                                            <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>

                                <div className="creditValue-section">
                                    <p>Credits Completed = {creditsCompleted}</p>
                                    <p>Credits Pending = {creditsPending}</p>
                                    <p>Total Credits for Graduation = {totalGraduationCredits}</p>
                                </div>
                            </div>

                            {showMore && (
                                <div className="progressbars-section">
                                    <DataTable value={dataTableData}>
                                        <Column field="area" header="Area" />
                                        <Column field="newValue" header="Completed / Total Credits" />
                                    </DataTable>
                                </div>
                            )}
                        </div>

                        <div className="view-button">
                            {showMore ? (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View Less</Link>
                            ) : (
                                <Link onClick={toggleShowMore} style={{ color: '#1067B8', fontSize: '16px' }}>View More</Link>
                            )}
                        </div>
                    </div>

                ) : (
                    // Content for monitor/desktop devices
                    < div className="GR-view">

                        <div className="circular-bar col-4">
                            <div className="circular-progress-bar">
                                <CircularProgressbarWithChildren value={creditsCompleted}>
                                    <div>
                                        <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits"><span style={{ fontSize: "32px", fontWeight: "bold" }}>{creditsCompleted}</span> /24</div>
                                        <div style={{ color: "#2F2E41", fontSize: "24px", textAlign: "center" }} className="circular-credits">Credits</div>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div>
                                <p>Credits Completed = {creditsCompleted}</p>
                                <p>Credits Pending = {creditsPending}</p>
                                <p>Total Credits for Graduation = {totalGraduationCredits}</p>
                            </div>
                        </div>
                        <div className="progressbars-section col-8">
                            <DataTable value={dataTableData}>
                                <Column field="area" header="Area" />
                                <Column field="pendingCredits" header="Pending Credits" />
                                <Column field="completedCredits" header="Completed Credits" />
                                <Column field="totalCredits" header="Total Credits" />
                            </DataTable>
                        </div>
                    </div>
                )}
            </>
        </div >

    );
}

export default HighSchoolGRsection;

import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./careergoal-modal.scss";
import { TabView, TabPanel } from 'primereact/tabview';


const CareerGoalModal = ( props) => {
  const [visible, setVisible] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const renderCards = (index) => {
    const header = (
      <img
        src={props.headerimage}
        className="cardHeaderImage"
        icon="pi pi-external-link"
        onClick={() => setVisible(true)}
      />
    );

    return (
      <div className="cardWrapper">
        <Card
          title={
            <>
              {/* <span>Health</span>{" "} */}
               <span>{props.cardTitle}</span>{" "} 
              <a
                href={props.cardLinks[index]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon pi pi-external-link"></i>
              </a>
              
            </>
          }
         
          header={header}
          className="cardResponsive"
        >
          <p className="cardText">{props.cardText}</p>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Button
        label="Learn more"
        onClick={() => setVisible(true)}
        className="healthcareButton"
        link
      />
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        className="dialogStyle"
      >
        <div className="dialogContent">
          <div className="banner">
            <img src={props.bannerImage} className="bannerImage" />
            {/* alt="Healthcare and Medicine banner" */}
            <div className="bannerText">{props.bannerText}</div>
          </div>

          <div className="dialogInnerContent">
            <div className="descriptionSection">
              <h3 className="descriptionText">{props.descriptionText}</h3>
            </div>

            <div className="salarySection">
              <div className="salaryDetails">
                <h2 className="salaryRange">
                  {props.salaryRange}
                  <br />
                  <span className="salarySubText">{props.salarySubText}</span>
                </h2>
                <div className="salaryDescription">
                  {props.salaryDescription}
                </div>
              </div>
            </div>
            
            <div className="grid">
              <div className="col-5 contentsection"  >
                
                <div className="educationSection">
                <img src={props.educationImage} className="educationImage" />
                </div>
                </div>
              <div className="col-7">
                <div className="pt-3"></div>
                <div>
                  <div className="educationTitle">{props.educationTitle}</div>
                  <div className="educationDescription">
                    {props.educationDescription}
                  </div>
                </div>
              </div>
            </div>

            
            <div className="careersSection ">
    <h3>{props.careersSection}</h3>
    
    <TabView className="careerTabs ">
    {props.tabsData.map((tab, index) => (
                 <TabPanel key={index} header={
                  <span
                  style={{
                    color: activeTabIndex === index ? 'green' : '', // Set color to green if tab is active
                    borderBottom: activeTabIndex === index ? '2px solid green' : '', // Set line below if tab is active
                    cursor: 'pointer', // Makes it clear it's clickable
                  }}
                  onClick={() => setActiveTabIndex(index)} // Set this tab as active when clicked
                >
                  {tab.header}
                </span>
                 }
                   noScroll>
                  
                 <h2 className="tabLinkHeader">{tab.link}</h2>

                 <div className="tabContent" style={{ display: 'flex', alignItems: 'center' }}>
                     {/* <img src={tab.tabImage} alt="Description for Image" className="tabImage" /> */}
                     <img src={require(`../../Assets/Images/Tabs Image/${tab.tabImage}`)} alt="Description for Image" className="tabImage" />
                     
                    
                     <div className="textContent">
                         <h2 className="tabTitle">{tab.tabTitle}</h2>
                         <p className="tabDescription">{tab.tabDescription}</p>
                         <div className="tabTitle2">{tab.tabTitle2}</div>
                         <p className="tabDescription2"> {tab.tabDescription2}</p>
                     </div>
                 </div>

            <div className="salarySection">
              <div className="salaryDetails">
              <div className="column">
                <h2 className="CostofSchooling">
                  {tab.CostofSchooling}
                  <br />
                  <span className="CostSubText">{tab.CostSubText}</span>
                </h2></div>
                
                <div className="column">
                
                <h2 className="CostofSchooling" >
                  {tab.salary}
                  <br />
                  <span className="CostSubText">{tab.salarytext}</span>
                </h2></div>
              </div>
              
            </div>
         
            
        </TabPanel>

            
        
        ))}
          </TabView>

          <div className="coursesSection">
              <h3>{props.coursesSection}</h3>
              <div className="coursesGrid">
                {renderCards(0)}
                {renderCards(1)}
                {renderCards(2)}
                {renderCards(3)}
              </div>
            </div>
            </div>

           </div>
        </div>
      </Dialog>
    </div>
  ) ;
};

export default CareerGoalModal;






import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import '../Dashboard/Dashboard.scss'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import Illustration from '../../../Assets/Loginimage.svg';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { CONTENT_HEADERS, CREATE_EXCEL_URL, CREATE_EXCEL_URL_HEADERS, GET_STUDENT_URL, SEND_BULK_EMAIL_URL, SEND_EMAIL_URL, GET_TOTAL_NUMBER_OF_USERS_URL, DELETE_USER_URL } from '../../../Utils/AdminService';
import CustomFilter from "../../../Shared-Component/ViewLists/CustomFilter/CustomFilter";
import { Dialog } from 'primereact/dialog';
import { faEnvelope, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';
import FilterChips from './FilterChips';
import { Toast } from 'primereact/toast';


function Principals() {
    const userRole = "Admin";
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState("principals");

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);
    const [isSendInvitationEnabled, setIsSendInvitationEnabled] = useState(false);

    const [filteredLists, setFilteredLists] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});

    const [selectedRows, setSelectedRows] = useState([]);

    const [schoolDetails, setSchoolDetails] = useState();
    const [name, setName] = useState();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [showBulkInvitationDialog, setShowBulkInvitationDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    const toast = useRef(null);


    useEffect(() => {
        const schoolId = localStorage.getItem('schoolId');
        fetchPrincipals('Principal', 'Assistant', schoolId);
    }, []);

    useEffect(() => {
        fetchDetails();
    }, []);


    useEffect(() => {
        setFilteredLists(fileData);
    }, [fileData]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handleFileUpload = (event) => {
        const file = event.files[0];
        setSelectedFile(file);
        setIsUploadEnabled(true);
    };

    const fetchPrincipals = (groupName, exact, schoolId) => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });
        axios
            .get(`${GET_STUDENT_URL}?groupName=${groupName}&exact=${exact}&schoolId=${schoolId}`)
            .then((response) => {
                setFileData(response.data);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
                setDatass(response.data);
            })
            .catch((error) => {
                setLoading(true);
                console.error('API Error:', error);
            });
    };

    const fetchDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });

        const schoolId = localStorage.getItem('schoolId');

        axios
            .get(GET_TOTAL_NUMBER_OF_USERS_URL + schoolId, { headers: CONTENT_HEADERS })
            .then((response) => {
                localStorage.setItem('schoolId', response.data.schools.schoolId);
                setSchoolDetails(response.data);
                setName(`${response.data.userDetails.firstName} ${response.data.userDetails.lastName}`);
                setLoading(false);
                setOpacityStyle({ opacity: 1, background: 'transparent' });
            })
            .catch((error) => {
                setLoading(true);
                console.log(error);
            });
    }

    const handleUpload = () => {
        if (!selectedFile) {
            console.error('No file selected');
            setFileData([]);
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        const schoolId = localStorage.getItem('schoolId');

        axios
            .post(CREATE_EXCEL_URL, formData, { headers: CREATE_EXCEL_URL_HEADERS })
            .then((response) => {
                fetchPrincipals('Principal', 'Assistant', schoolId);
                setFileData(response.data);
            })
            .catch((error) => {
                console.log(error);
                if (toast.current) {
                    toast.current.clear();
                    toast.current.show([{ life: 10000, severity: 'error', summary: 'Error', detail: error.response.data.message }]);
                }
            });
    };

    const handleAddNewClick = () => {
        navigate("/admin/principals/create");
    };

    const actionBodyTemplate = (rowData) => {
        const isEditDisabled = rowData.status === "Completed";
        return (
            <React.Fragment>
                <Button
                    rounded
                    outlined
                    severity="danger"
                    className="action-button"
                    onClick={() => handleInvitation(rowData)}
                    disabled={isEditDisabled}
                    style={{ display: isEditDisabled ? "none" : "inline-block" }}
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </Button>

                <Button
                    rounded
                    outlined
                    onClick={() => handleEdit(rowData)}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>

                <Button
                    rounded
                    outlined
                    onClick={() => handleDeleteClick(rowData)}
                    className="action-button"
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </React.Fragment>
        );
    };

    const handleInvitation = (selectedRowId) => {
        setSelectedRowId(selectedRowId.userId);
        setOpenDialog(true);
        setSelectedUser(selectedRowId.firstName)
    }

    const hideOpenDialog = () => {
        setOpenDialog(false);
    }

    const handleEdit = (rowData) => {
        navigate('/admin/principals/create', { state: { rowData } });
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRowId(rowData.userId);
        setShowDeleteDialog(true);
    }

    const handleDeleteAction = () => {

        axios
            .delete(DELETE_USER_URL + selectedRowId, { headers: CONTENT_HEADERS })
            .then((response) => {
                setShowDeleteDialog(false);
                window.location.reload(true);
            }).catch((error) => {
                console.log("API Error:", error)
            })
    }

    const [selectedField, setSelectedField] = useState([]);


    const applyFilter = (field) => {
        const updatedFilteredLists = fileData.filter((item) => {
            const selectedFieldValues = Object.keys(checkedItems).filter(
                (key) => checkedItems[key]
            );

            setSelectedField(selectedFieldValues);

            if (selectedFieldValues.length === 0) {
                return true;
            }

            return selectedFieldValues.includes(item[field]);
        });

        setFilteredLists(updatedFilteredLists);

        const overlayElement = document.querySelector('.p-column-filter-overlay');
        if (overlayElement) {
            overlayElement.style.display = 'none';
        }
    };


    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleCheckboxChange = (item) => {
        const updatedCheckedItems = {
            ...checkedItems,

            [item]: !checkedItems[item],
        };

        setCheckedItems(updatedCheckedItems);

        const selectedFieldValues = Object.keys(updatedCheckedItems).filter(
            (key) => updatedCheckedItems[key]
        );

        setSelectedFilters(selectedFieldValues);

    };

    const handleClearAllFilters = () => {

        setCheckedItems({});
        setSelectedField([])

        setSelectedFilters([]);

        setDatass(fileData);
        setFilteredLists(fileData);

        const overlayElement = document.querySelector('.p-column-filter-overlay');
        if (overlayElement) {
            overlayElement.style.display = 'none';
        }

    };

    const handleSendInvitation = () => {

        axios
            .get(SEND_EMAIL_URL + selectedRowId, { headers: CONTENT_HEADERS })
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("API Error:", error)
            })
    }

    const showDialog = () => {
        setShowBulkInvitationDialog(false);
        setShowDeleteDialog(false);
    }

    const handleBulkSendInvitations = (selectedRowIds) => {
        setShowBulkInvitationDialog(true);
        setSelectedRowIds(selectedRowIds);
    }

    const openDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" severity="secondary" outlined onClick={hideOpenDialog} />
            <Button label="Send" icon="pi pi-check" severity="danger" onClick={handleSendInvitation} />
        </React.Fragment>
    );

    const handleBulkSendInvitation = () => {
        const hasCompletedStatus = selectedRowIds.some((userId) => {
            const user = filteredLists.find((item) => item.userId === userId);
            return user && user.status === "Completed";
        });

        if (hasCompletedStatus) {
            alert("You cannot send invitations to users with 'Completed' status.");
            return;
        }

        const usersToSendInvitation = selectedRowIds.filter((userId) => {
            const user = filteredLists.find((item) => item.userId === userId);
            return user && user.status !== "Completed";
        });

        if (usersToSendInvitation.length === 0) {
            alert("Please select users with 'Uninvited' or 'Pending' status.");
            return;
        }

        axios
            .post(SEND_BULK_EMAIL_URL, selectedRowIds, { headers: CONTENT_HEADERS })
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("API Error:", error)
            })
    }

    const bulkInvitationDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={showDialog} />
            <Button label="Send" icon="pi pi-check" severity="danger"
                onClick={() => handleBulkSendInvitation()}
            />
        </React.Fragment>
    );

    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="No" severity="secondary" icon="pi pi-times" outlined onClick={showDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger"
                onClick={handleDeleteAction}
            />
        </React.Fragment>
    );

    const [showHeader, setShowHeader] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const [datass, setDatass] = useState([]);


    return (
        <div className='admin-principal-container'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="flex justify-content-between align-items-center mx-6 pb-4 admin-section">
                <h2>Principals</h2>

                <span className="p-input-icon-right">
                    <div className="flex align-items-center justify-content-end">
                        <InputText placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <i className="pi pi-search" style={{ position: "absolute", marginRight: "20px" }} />
                    </div>
                </span>
            </div>
            <Card className='principal-page-card mx-6 my-2'>
                <Toast ref={toast} />
                <div className="principal-page">
                    <div className="principal-page-head w-full">
                        <div className="principal-page-left flex justify-content-center mx-6">
                            <InputText
                                type="text"
                                value={selectedFile ? selectedFile.name : ''}
                                readOnly
                                placeholder="Browse a file"
                                className='principal-input -mr-3'
                            />
                            <FileUpload
                                chooseLabel="Select File"
                                uploadLabel="Upload"
                                value={''}
                                onSelect={handleFileUpload}
                                mode="basic"
                                accept=".xlsx"
                            />
                            <div className="ml-3">
                                <Button onClick={handleUpload} disabled={!isUploadEnabled} className='gap-2'>
                                    <i className="pi pi-upload"></i>
                                    Upload File
                                </Button>
                            </div>
                        </div>
                        <div className="principal-page-right flex justify-content-center mx-6 gap-3">
                            <Button
                                onClick={handleAddNewClick} >
                                + Add New
                            </Button>
                        </div>
                    </div>
                    <hr className='my-4' />
                </div>

                <div className="p-d-flex p-flex-wrap mx-6">
                    {filteredLists.length > 0 ? (
                        <div className="card m-0 p-0 table-section">
                            {selectedField.length > 0 ?
                                (
                                    <div className='pb-3'>
                                        <FilterChips
                                            selectedFilters={selectedField}

                                            onRemoveFilter={(removedFilter) => {
                                                setCheckedItems((prevCheckedItems) => {
                                                    const updatedCheckedItems = { ...prevCheckedItems };
                                                    delete updatedCheckedItems[removedFilter];

                                                    return updatedCheckedItems;
                                                });
                                            }}

                                            handleClearAllFilters={handleClearAllFilters}
                                        />
                                    </div>
                                ) : ''
                            }
                            <Card className="flex flex-column justify-content-center items-center ">
                                <DataTable
                                    selection={selectedRows}
                                    onSelectionChange={(e) => {
                                        setSelectedRows(e.value);
                                        setSelectedRowIds(e.value.map(row => row.userId))
                                        if (e.value.length > 0) {
                                            setIsSendInvitationEnabled(true);
                                            setShowHeader(true);
                                        } else {
                                            setIsSendInvitationEnabled(false);
                                            setShowHeader(false);
                                        }
                                    }}
                                    value={datass.filter((list) =>
                                        list.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        list.emailId.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        list.registrationId.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                        .filter((list) => filteredLists.includes(list))}
                                    selectionMode="checkbox"
                                    paginator rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    tableStyle={{ minWidth: '50rem' }}
                                    columnResizeMode="expand"
                                    resizableColumns
                                    showGridlines
                                    className={
                                        `${showHeader ? 'hide-header' : 'show-header'}`
                                    }
                                >
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                        field="registrationId"
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-3'>
                                                {showHeader && (
                                                    <Button
                                                        className='gap-2 flex align-items-center cursor-pointer'
                                                        onClick={() => handleBulkSendInvitations(selectedRowIds)}
                                                        disabled={!isSendInvitationEnabled}
                                                    >
                                                        <i className="pi pi-envelope"></i> Send Invitation
                                                    </Button>
                                                )}
                                            </div>
                                        ) : "School ID"}
                                    />
                                    <Column
                                        field="firstName"
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-1'>
                                                {showHeader && (
                                                    <></>
                                                )}
                                            </div>
                                        ) : "Name"}
                                    />
                                    <Column field="emailId"
                                        header={showHeader ? (
                                            <div className='flex align-items-center justify-content-center px-1'>
                                                {showHeader && (
                                                    <></>
                                                )}
                                            </div>
                                        ) : "Email"}
                                    />
                                    <Column field="phoneNumber" header="Phone Number" />
                                    {/* <Column field="schools.schoolName" header="School" />
                                    <Column field="userType" header="Role" /> */}
                                    <Column
                                        field="status"
                                        header="Status"
                                        filter
                                        filterClear
                                        filterApply={
                                            <CustomFilter
                                                checkboxes={[
                                                    { label: "Uninvited", value: "Uninvited" },
                                                    { label: "Pending", value: "Pending" },
                                                    { label: "Completed", value: "Completed" },
                                                ]}
                                                checkedItems={checkedItems}
                                                onCheckboxChange={handleCheckboxChange}
                                                onApplyFilter={() => applyFilter("status")}
                                            />
                                        }
                                    />
                                    <Column
                                        header="Actions"
                                        body={actionBodyTemplate}
                                        exportable={false}
                                    ></Column>
                                </DataTable>
                            </Card>
                        </div>

                    ) : (

                        <Card className='flex flex-column justify-content-center items-center mt-2 m-4'>
                            <div className="flex flex-column justify-content-center align-items-center">
                                <Image src={Illustration} alt="Image" width="350px" />
                                <p className='m-5'>To see information here, please start adding a new principal</p>
                            </div>
                        </Card>
                    )}
                </div>
            </Card >

            <Dialog visible={openDialog} style={{ width: '32rem', fontSize: '1.5rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Send Invitation" modal footer={openDialogFooter}
                onHide={hideOpenDialog}
            >
                <div className="confirmation-content flex text-center">
                    {selectedUser && (
                        <span style={{ fontSize: '1.2rem' }}>
                            Are you sure you want to send an invitation to <b>{selectedUser}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog
                visible={showBulkInvitationDialog}
                style={{ width: '30rem', fontSize: '1.5rem' }}
                onHide={showDialog}
                footer={bulkInvitationDialogFooter}
                header="Send Invitation"
            >
                <div className="confirmation-content flex text-center">
                    <span style={{ fontSize: '1.2rem' }}>
                        Are you sure you want to send an invitations to selected users?
                    </span>
                </div>
            </Dialog>

            <Dialog
                visible={showDeleteDialog}
                style={{ width: '30rem', fontSize: '1.5rem' }}
                onHide={showDialog}
                footer={deleteDialogFooter}
                header="Delete User"
            >
                <div className="confirmation-content flex text-center">
                    <span style={{ fontSize: '1.2rem' }}>
                        Are you sure you want to delete the user?
                    </span>
                </div>
            </Dialog>
            
        </div >
    );
};

export default Principals;
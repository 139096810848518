import React, { useState, useEffect } from "react";
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import ProfileCard from '../../../Shared-Component/ProfileCard/profileCard';
import StatusCard from '../../../Shared-Component/Status-Card/status-card';
import PieChart from '../../../Shared-Component/PieChart/PieChart';
import './Dashboard.scss'
import ViewCard from '../../../Shared-Component/ViewLists/ViewCard';
import axios from 'axios';
import { GET_TEACHER_DETAILS_URL, TEACHER_URL_HEADERS } from '../../../Utils/TeacherService';
import { CONTENT_HEADERS, GET_GOAL_DETAIL } from '../../../Utils/PrincipalService';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';

const Dashboard = () => {
    const userRole = 'Teacher';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');
    const [teacherDetails, setTeacherDetails] = useState();
    const [teacherName, setTeacherName] = useState();
    const [pieChartData, setPieChartData] = useState(null);
    const [name, setName] = useState();

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    const [showSeeAllLink, setShowSeeAllLink] = useState();
    const [isDataAvailable, setIsDataAvailable] = useState();

    useEffect(() => {
        fetchTeacherDetails();
    }, []);


    const fetchGoalDetails = () => {

        const schoolId = localStorage.getItem('schoolId');
        const userId = localStorage.getItem("userId")
        axios
            .get(GET_GOAL_DETAIL + schoolId + "/" + userId, { headers: CONTENT_HEADERS })
            .then((response) => {

                if (response && response.data) {
                    const areAllValuesNonZero = Object.values(response.data).some(value => {
                        if (value && typeof value === 'object') {
                            const innerObject = Object.values(value).find(innerValue => innerValue !== 0);
                            if (innerObject) {
                                return true;
                            }
                        }
                        return false;
                    });
                
                const isDataAvailable = areAllValuesNonZero;
                setIsDataAvailable(isDataAvailable)

                setPieChartData(response?.data);
            }
                setShowSeeAllLink(!response.data.someCondition);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

    }

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const fetchTeacherDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });

        const teacherId = localStorage.getItem('userId');
        axios
            .get(GET_TEACHER_DETAILS_URL + teacherId, { headers: TEACHER_URL_HEADERS })
            .then((response) => {
                setTeacherDetails(response.data);
                const teacherFirstNames = response.data?.groupUsersInfo;
                setTeacherName(teacherFirstNames)
                setName(`${response.data.teacher.firstName} ${response.data.teacher.lastName}`);
                setOpacityStyle({ opacity: 1, background: 'transparent' });

                localStorage.setItem('schoolId', response.data?.teacher?.schools?.schoolId);

                setTimeout(() => {
                    fetchGoalDetails();
                }, 3000);
            });
    };

    return (
        <div className='teacher-dashboard'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="grid teacher-cards">
                <div className="col-4">
                    <ProfileCard
                        userRole={userRole}
                        name={`${teacherDetails?.teacher?.firstName} ${teacherDetails?.teacher?.lastName}`}
                        gender={teacherDetails?.teacher?.gender}
                        schoolId="3141232"
                        grade="9th Grade"
                        year="June 2026"
                        roleDescription="Personal Pathway Teacher"
                    ></ProfileCard>
                </div>
                <div className="col-8">
                    <StatusCard
                        userRole={userRole}
                        totalStudents={teacherDetails?.totalStudents}
                        notStarted={teacherDetails?.tenYearPlanNotStarted}
                        studentsStarted={teacherDetails?.tenYearPlanStarted}
                        title={`Students Personal Pathway Status`}
                    ></StatusCard>
                </div>

                <div className='chart-content col-8'>
                    <PieChart pieChartData={pieChartData} isDataAvailable={isDataAvailable} />
                </div>
                <div className='viewcard-content col-4'>
                    <ViewCard title="Students Personal Pathway Status" forwhom="students" teacherName={teacherName} userRole="Teacher"  showSeeAllLink={showSeeAllLink}>

                    </ViewCard>
                </div>
            </div>

        </div>
    );
};

export default Dashboard;
import React, { useEffect, useState } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useNavigate } from 'react-router-dom';
import ProfileCard from '../../../Shared-Component/ProfileCard/profileCard';
import StatusCard from '../../../Shared-Component/Status-Card/status-card';
import './Dashborad.scss'
import PieChart from '../../../Shared-Component/PieChart/PieChart';
import ViewCard from '../../../Shared-Component/ViewLists/ViewCard';
import axios from 'axios';
import { CONTENT_HEADERS, GET_GOAL_DETAILS, GET_PRINCIPAL_DETAILS_URL, GET_TEACHER_NAME } from '../../../Utils/PrincipalService';
import Spinner from '../../../Shared-Component/Spinner/Gif-Loader';


const Dashboard = () => {
    const userRole = 'Principal';
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');
    const [principalDetails, setPrincipalDetails] = useState();
    const [principalName, setPrincipalName] = useState();

    const [pieChartData, setPieChartData] = useState(null);
    const [name, setName] = useState();

    const [loading, setLoading] = useState(false);
    const [opacityStyle, setOpacityStyle] = useState({ opacity: 1 });
    const [showSeeAllLink, setShowSeeAllLink] = useState();
    const [isDataAvailable, setIsDataAvailable] = useState();

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchPrincipalDetails();
        fetchTeacherDetails();
    }, []);


    const fetchGoalDetails = () => {
        const schoolId = localStorage.getItem('schoolId');

        axios
            .get(GET_GOAL_DETAILS + schoolId, { headers: CONTENT_HEADERS })
            .then((response) => {
                const areAllValuesNonZero = Object.values(response.data).some(value => {
                    const innerObject = Object.values(value).find(innerValue => innerValue !== 0);
                    if (innerObject) {
                        return true;
                    }
                    return false;
                });

                const isDataAvailable = areAllValuesNonZero;
                setIsDataAvailable(isDataAvailable);
                setPieChartData(response.data);
                setShowSeeAllLink(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    const fetchPrincipalDetails = () => {
        setLoading(true);
        setOpacityStyle({ opacity: 0.5 });



        const principalId = localStorage.getItem('userId');
        axios
            .get(GET_PRINCIPAL_DETAILS_URL + principalId, { headers: CONTENT_HEADERS })
            .then((response) => {
                setPrincipalDetails(response.data);
                localStorage.setItem('schoolId', response.data?.userDTO?.schools?.schoolId);
                setName(`${response.data.userDTO?.firstName} ${response.data.userDTO?.lastName}`);
                setOpacityStyle({ opacity: 1, background: 'transparent' });

                setTimeout(() => {
                    fetchGoalDetails();
                }, 3000);
            })
    }

    const fetchTeacherDetails = () => {


        axios
            .get(GET_TEACHER_NAME, { headers: CONTENT_HEADERS })
            .then((response) => {
                const detail = response.data;
                setPrincipalName(detail);
            })
    }

    return (
        <div className='principal-dashboard'>
            <div className="spinner-section">
                {loading ? <Spinner /> : null}
            </div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
                name={name}
            />
            <div className="grid principal-cards">
                <div className="col-4">
                    <ProfileCard
                        userRole={userRole}
                        name={`${principalDetails?.userDTO?.firstName} ${principalDetails?.userDTO?.lastName}`}
                        gender={principalDetails?.userDTO?.gender}
                        schoolId="3141232"
                        grade="9th Grade"
                        year="June 2026"
                        roleDescription="High School Principal"
                    ></ProfileCard>
                </div>
                <div className="col-8">
                    <StatusCard
                        userRole={userRole}
                        totalStudents={principalDetails?.totalStudents}
                        notStarted={principalDetails?.totalNotStarted}
                        studentsStarted={principalDetails?.totalStarted}
                        title={`Students Personal Pathway Status`}
                    ></StatusCard>
                </div>
                <div className="col-8">
                    <PieChart pieChartData={pieChartData} isDataAvailable={isDataAvailable} />
                </div>
                <div className="col-4">
                    <ViewCard title="Personal Pathway Status Teachers" forwhom="teachers" principalName={principalName} userRole="Principal" showSeeAllLink={showSeeAllLink}></ViewCard>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

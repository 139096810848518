import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import '../../../Dashboard/MobileStudentForm/MobileStudentForm.scss';
import NavBar from '../../../../../Shared-Component/StackNavBar/StackNavBar';
import { Button } from 'primereact/button';
import CareerGoalsForm from '../../StudentForm/Career-Goals-Form/CareerGoalsForm';


const MobileCareerGoalsForm = () => {

    const userRole = 'Student';
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

    };



    return (
        <div className="mob-careergoals-section">

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='m-5 '>
                <div className='mob-career-link' >
                    <Link to="/student/dashboard"> <FaChevronLeft /> Back To Dashboard</Link>
                </div>
                <div className='mob-career-button mt-3 mb-3' >
                    <Button className='Button'>2. Career Goal </Button>
                </div>
                <CareerGoalsForm></CareerGoalsForm>
            </div>

        </div>
    );
};

export default MobileCareerGoalsForm;

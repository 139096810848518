import React, { useState, useEffect } from 'react'
import './Academic-Pathways-Form.scss';
import "primeicons/primeicons.css";
import { Card } from 'primereact/card';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button';
import { AUTHORIZATION_HEADERS, ACADEMIC_PATHWAYS, GET_ACADEMIC_PATHWAYS, CONTENT_HEADERS } from '../../../../../Utils/StudentService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { Accordion, AccordionTab } from 'primereact/accordion';


const AcademicPathwaysForm = ({ onNextButtonClick, handleBackButtonClick, onAcademicPathwayUpdate, academicPathwayState }) => {

    const navigate = useNavigate();

    const [creativeMedia, setCreativeMedia] = useState("");
    const [getResponse, setGetResponse] = useState({});
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        fetchAcademicPathways();
    }, []);

    const fetchAcademicPathways = () => {
        axios
            .get(GET_ACADEMIC_PATHWAYS, { headers: CONTENT_HEADERS })
            .then((response) => {

                setGetResponse(response.data);
                const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
                const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);

                if (response.data.academiesPathwayVersion?.academiesPathway?.length > 0) {

                    const latestVersion = ((response.data.academiesPathwayVersion?.academiesPathway?.length) - 1);
                    const latestAcademyPathway = response.data.academiesPathwayVersion?.academiesPathway[latestVersion];

                    const creativeMedias = (latestAcademyPathway?.academiesPathwayCategory).filter(item => item.academiesCategoryName === "Academy of Human Services");
                    selectedCategory["Academy of Human Services"] = creativeMedias[0].academiesPathWay[0];

                    setCreativeMedia(creativeMedias[0].academiesPathWay[0]);

                    const business_Entrepreneurship = (latestAcademyPathway?.academiesPathwayCategory).filter(item => item.academiesCategoryName === "Academy of Innovation and Design");
                    selectedCategory["Academy of Innovation and Design"] = business_Entrepreneurship[0].academiesPathWay[0];
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const pathWays = [
        {
            label: 'Academy of Human Services', key: 'Academy of Human Services',
            categories: [
                { label: 'Agriculture, Food & Natural Resources', key: 'Agriculture, Food & Natural Resources' },
                { label: 'Business Management Finance & Marketing', key: 'Business Management Finance & Marketing' },
                { label: 'Health Services', key: 'Health Services' },
                { label: 'Culinary Arts', key: 'Culinary Arts' },
                { label: 'Sustainable Hospitality & Tourism', key: 'Sustainable Hospitality & Tourism' },
                { label: 'Education', key: 'Education' },
            ]
        },
        {
            label: 'Academy of Innovation and Design', key: 'Academy of Innovation and Design',
            categories: [
                { label: 'Building & Construction', key: 'Building & Construction' },
                { label: 'Media & Entertainment', key: 'Media & Entertainment' },
                { label: 'Engineering Technology', key: 'Engineering Technology' },
                { label: 'Transportation Services', key: 'Transportation Services' },
                { label: 'Information Technology & Digital Transformation', key: 'Information Technology & Digital Transformation' },
                { label: 'Manufacturing', key: 'Manufacturing' },
            ]
        },

    ];

    const [selectedCategory, setSelectedCategory] = useState({});

    const handleOptionChange = (goalId, categoryId) => {
        setSelectedCategory(prevSelectedAnswers => ({
            [goalId]: categoryId
        }));
    };

    const mobilePathWays = {
        'Academy of Human Services': {
            categories: [
                { label: 'Agriculture, Food & Natural Resources', key: 'Agriculture, Food & Natural Resources' },
                { label: 'Business Management Finance & Marketing', key: 'Business Management Finance & Marketing' },
                { label: 'Health Services', key: 'Health Services' },
                { label: 'Culinary Arts', key: 'Culinary Arts' },
                { label: 'Sustainable Hospitality & Tourism', key: 'Sustainable Hospitality & Tourism' },
                { label: 'Education', key: 'Education' },
            ]
        },
        'Academy of Innovation and Design': {
            categories: [
                { label: 'Building & Construction', key: 'Building & Construction' },
                { label: 'Media & Entertainment', key: 'Media & Entertainment' },
                { label: 'Engineering Technology', key: 'Engineering Technology' },
                { label: 'Transportation Services', key: 'Transportation Services' },
                { label: 'Information Technology & Digital Transformation', key: 'Information Technology & Digital Transformation' },
                { label: 'Manufacturing', key: 'Manufacturing' },
            ]
        },
    }

    const handleNextClick = () => {
        const selected_Human_Services = selectedCategory["Academy of Human Services"];
        const selected_Innovation_Design = selectedCategory["Academy of Innovation and Design"];

        const academic_pathways = {
            "academiesPathwayCategory": [
                {
                    "academiesCategoryName": "Academy of Human Services",
                    "academiesPathWay": [selected_Human_Services]
                },
                {
                    "academiesCategoryName": "Academy of Innovation and Design",
                    "academiesPathWay": [selected_Innovation_Design]
                },
            ],
        }

        axios
            .post(ACADEMIC_PATHWAYS, academic_pathways.academiesPathwayCategory, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                if (window.innerWidth > 1008) {
                    onAcademicPathwayUpdate(academic_pathways.academiesPathwayCategory);
                    if ((tabState && buttonState) || (tabState === null && buttonState && !academicPathwayState)) {
                        onNextButtonClick();
                    }
                }
                else if (response.status === 200) {
                    navigate('/student/highSchoolProgress');
                } else {
                    console.log(response)
                }
            }
            )
            .catch((error) => {
                console.error('API Error:', error);
            }
            )

            setIsDisabled(true);
    };

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {
        if (!academicPathwayState && !tabState) {
            setButtonState(true);
        }

        if (academicPathwayState) {
            handleNextClick();
            setTabState(true);
        }
    }, [academicPathwayState]);


    const handleBackClick = () => {
        if (window.innerWidth > 1008) {
            handleBackButtonClick();
        }
        else {
            navigate('/student/academicgoals');
        }
    };

    const [activeButton, setActiveButton] = useState(null);
    const [selectedPathway, setSelectedPathway] = useState({});

    const handleSelectPathway = (label) => {
        setActiveButton(label);

        if (selectedPathway[label]) {
            setSelectedPathway((prevState) => ({
                ...prevState,
                [label]: prevState[label],
            }));
        } else {
            setSelectedPathway((prevState) => ({
                ...prevState,
                [label]: {},
            }));
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    const accordionItems = [
        {
            title: "Academies Pathways",
            content: (
                <div className="accordion-content">
                    <>
                        <div className="academic-pathways-subheader">Choose one option. Click here to see all the information about <a href="/student/help/courses" style={{ color: "#1575E5", cursor: "pointer" }}>Academies Pathways Program</a></div>
                        {/* <div className="academic-pathways-subheaders">Note: All require a cumulative GPA of 3.0 or higher</div> */}
                        <div className="flex flex-row flex-wrap gap-3 pathways-content">
                            {pathWays.map((pathway) => {
                                return (
                                    <div className='m-5'>
                                        <div className='academy-label'><b>{pathway.label}</b></div>
                                        <div className="pathway-radio-buttons">
                                            <div className="card flex justify-content-left">
                                                <div className="flex flex-column gap-3 pl-3">
                                                    {pathway.categories.map((category) => {
                                                        return (
                                                            <div key={category.label} className="flex align-items-center">
                                                                <RadioButton inputId={category.key} name={`pathway_${pathway.id}`} value={category} onChange={(e) => handleOptionChange(pathway.label, category.key)} checked={selectedCategory[pathway.label] === category.key} />
                                                                <label htmlFor={category.key} className="ml-2 academy-labels">{category.label}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex flex-row flex-wrap gap-8 mt-5 pathways-mobile-content">
                            <div className="flex flex-row flex-wrap gap-3 pathway-mob">
                                {Object.keys(mobilePathWays).map((label) => (
                                    <div className='mobile-button-section' key={label}>
                                        <div className={label === activeButton ? "pathway-btn-active" : "pathway-btn"}
                                            onClick={() => handleSelectPathway(label)}>
                                            {label}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {mobilePathWays[activeButton]?.categories && (
                                <div className="pathway-radio-buttons">
                                    <div className="card flex justify-content-left">
                                        <div className="flex flex-column gap-3 pl-3">
                                            {mobilePathWays[activeButton].categories.map((category) => {
                                                return (
                                                    <div key={category.label} className="flex align-items-center">
                                                        <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => handleOptionChange(activeButton, category.key)} checked={selectedCategory[activeButton] === category.key} />
                                                        <label htmlFor={category.key} className="ml-2 academy-labels">{category.label}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                </div>
            )
        },
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    <>
                        {teacherNotes ? (
                            <div className='teacher-notes-section m-5'>
                                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                            </div>
                        ) : (
                            <div className='teacher-notes-section m-5'>
                                <p>There are no teacher notes.</p>
                            </div>
                        )}
                    </>
                </div>
            )
        }
    ];

    return (
        <div className="card academy-section">
            <Card className='m-4'>
                <div className="academic-pathways-form">
                    <div className='desktop-pathway'>
                        <div className="academic-pathways-header">Career Academies & CTE Pathways</div>
                        <div className="academic-pathways-subheader">Choose one option. Click here to see all the information about <a href="/student/help/courses" style={{ color: "#1575E5", cursor: "pointer" }}>Academies Pathways Program</a></div>
                        {/* <div className="academic-pathways-subheaders">Note: All require a cumulative GPA of 3.0 or higher</div> */}
                        <div className="flex flex-row flex-wrap gap-3 pathways-content">
                            {pathWays.map((pathway) => {
                                return (
                                    <div className='m-5'>
                                        <div className='academy-label'><b>{pathway.label}</b></div>
                                        <div className="pathway-radio-buttons">
                                            <div className="card flex justify-content-left">
                                                <div className="flex flex-column gap-3 pl-3">
                                                    {pathway.categories.map((category) => {
                                                        return (
                                                            <div key={category.label} className="flex align-items-center">
                                                                <RadioButton inputId={category.key} name={`pathway_${pathway.id}`} value={category} onChange={(e) => handleOptionChange(pathway.label, category.key)} checked={selectedCategory[pathway.label] === category.key} />
                                                                <label htmlFor={category.key} className="ml-2 academy-labels">{category.label}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}

                        </div>


                        <div className="flex flex-row flex-wrap gap-8 mt-5 pathways-mobile-content">
                            <div className="flex flex-row flex-wrap gap-3 pathway-mob">
                                {Object.keys(mobilePathWays).map((label) => (
                                    <div className='mobile-button-section' key={label}>
                                        <div className={label === activeButton ? "pathway-btn-active" : "pathway-btn"}
                                            onClick={() => handleSelectPathway(label)}>
                                            {label}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {mobilePathWays[activeButton]?.categories && (
                                <div className="pathway-radio-buttons">
                                    <div className="card flex justify-content-left">
                                        <div className="flex flex-column gap-3 pl-3">
                                            {mobilePathWays[activeButton].categories.map((category) => {
                                                return (
                                                    <div key={category.label} className="flex align-items-center">
                                                        <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => handleOptionChange(activeButton, category.key)} checked={selectedCategory[activeButton] === category.key} />
                                                        <label htmlFor={category.key} className="ml-2 academy-labels">{category.label}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Divider />
                        </div>

                        {teacherNotes && (
                            <div className='teacher-notes-section m-5'>
                                <h3 className='head'>Teacher Notes</h3>
                                <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                            </div>
                        )}
                    </div>

                    <div className='mobile-pathway'>
                        <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                            {accordionItems.map((item, index) => (
                                 item.title === "Teacher Notes" && !teacherNotes ? null : (
                                <AccordionTab key={index} header={
                                    <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                        <div className="header-text">{item.title}</div>
                                        <div className="header-icon">
                                            <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                        </div>
                                    </div>
                                }>
                                    <div className="accordion-content">
                                        {item.content}
                                    </div>
                                </AccordionTab>
                                 )
                            ))}
                        </Accordion>
                    </div>

                    <footer className="form-footer">
                        <Button label="Back" severity="secondary" onClick={handleBackClick} />
                        <Button
                            label="Save and Continue"
                            onClick={() => { handleNextClick() }}
                            disabled={isDisabled} 
                        />
                    </footer>
                </div>
            </Card>
        </div>
    );
};

export default AcademicPathwaysForm
const CourseCreditMapping = {
    "English Language Arts 1": 1,
    "English Language Arts 2": 1,
    "English Language Arts 3": 1,
    "AP Language & Composition": 1,
    "English Language Arts 4": 1,
    "AP Literature & Composition": 1,

    "Participation In Democracy": 0.5,
    "Modern History Of Hawai`i": 0.5,
    "World History": 1,
    "US History": 1,
    "AP US History": 1,
    "Economics": 0.5,
    "Hawaiian Studies": 0.5,
    "Geography": 0.5,
    "Psychology": 0.5,
    "AP Psychology I/II": 1,

    "Geometry": 1,
    "Algebra 1": 1,
    "Algebra 2": 1,
    "Introduction To College Mathematics": 1,
    "Precalculus": 0.5,
    "Trigonometry": 0.5,
    "AP Computer Science Principles": 1,

    "Biology": 1,
    "Earth System Science": 1,
    "Marine Science": 1,
    "Human Physiology": 1,
    "Chemistry": 1,
    "Honor Chemistry": 1,
    "Physics": 1,
    "AP Biology": 1,

    "Physical Education Lifetime Fitness": 0.5,
    "Body Conditioning": 0.5,
    "Team Sports 1": 0.5,
    "Team Sports 2": 0.5,
    "Weight / Resistance 1A": 0.5,
    "Weight / Resistance 1B": 0.5,
    "Weight / Resistance 2A": 0.5,
    "Weight / Resistance 2B": 0.5,
    "Recreational Leadership": 0.5,

    "Health Today/ Tomorrow": 0.5,

    "Personal Transition Plan": 0.5,

    //Electives

    "Band 1": 1,
    "Band 2": 1,
    "Band 3": 1,
    "Band 4": 1,

    "Chorus 1": 1,
    "Chorus 2": 1,
    "Chorus 3": 1,
    "Chorus 4": 1,

    "Piano 1": 1,
    "Piano 2": 1,
    "Piano 3": 1,
    "Piano 4": 1,

    "Ukulele 1": 1,
    "Ukulele 2": 1,
    "Ukulele 3": 1,
    "Ukulele 4": 1,

    "Jazz Band 1": 1,
    "Jazz Band 2": 1,
    "Jazz Band 3": 1,
    "Jazz Band 4": 1,

    "Play Production 1": 1,
    "Play Production 2": 1,
    "Play Production 3": 1,
    "Play Production 4": 1,

    "General Arts 1": 1,
    "General Arts 2": 1,
    "General Arts 3": 1,

    "Sculpture 1": 1,
    "Sculpture 2": 1,
    "Sculpture 3": 1,

    "Drawing / Painting 1": 1,
    "Drawing / Painting 2": 1,
    "Drawing / Painting 3": 1,
    "Directed Study Art Problems": 1,

    "Japanese 1": 1,
    "Japanese 2": 1,
    "Japanese 3": 1,
    "Japanese 4": 1,

    "Spanish 1": 1,
    "Spanish 2": 1,
    "Spanish 3": 1,
    "Spanish 4": 1,

    "Hawaiian 1": 1,
    "Hawaiian 2": 1,
    "Hawaiian 3": 1,
    "Hawaiian 4": 1,

    // CTE Electives

    "Foundations of Agriculture, Food, & Natural Resources": 1,
    "Principles Food Production": 1,
    "Food Products & Process Systems 1": 1,
    "Food Products & Process Systems 2": 1,

    "Foundations of Business & Marketing": 1,
    "Entrepreneurship 1": 1,
    "Entrepreneurship 2": 1,

    "Foundations of Culinary Arts": 1,
    "Culinary Arts: Food Preparation": 1,
    "Advanced Culinary Arts: Pastry and Savory": 1,
    "Kitchen & Restaurant Operations": 1,

    "Foundations of Health Services": 1,
    "Advanced Health Services": 1,
    "Nursing Services 1": 1,
    "Nursing Services WK-Base Learn": 1,

    "Foundations of Sustainable Hospitality Tourism Management": 1,
    "Sustainable Hospitality Tourism Management 1": 1,
    "Sustainable Hospitality Tourism Management 2": 1,

    "Foundations of Education": 1,

    "Foundations Automotive Main & Light Repair": 1,
    "Automotive Main & Light Repair 1": 1,

    "Foundation of Manufacturing": 1,

    "Foundations of Computer Systems and technology": 1,
    "Cybersecurity 1": 1,
    "Cybersecurity 2": 1,

    "Foundations of Construction": 1,
    "Residential & Commercial Construction 1": 1,
    "Residential & Commercial Construction 2": 1,
    "Residential & Commercial Construction WK-Base Learn": 1,

    "Foundations of Creative Media - Digital Video": 1,
    "Digital Video Technology": 1,
    "Film Media Production 1": 1,
    "Foundations of Creative Media – Design": 1,
    "Digital Design 1": 1,
    "Digital Design 2": 1,
    "Digital Design WK-Base Learn": 1,

    "Foundations of Engineering Technology": 1,
    "Engineering Technology 1": 1,
    "Engineering Technology 2": 1,
    "Engineering Technology 3": 1,

    // Early College Electives

    "Math 103-College Algebra": 1,
    "Math 140-Pre-Calculus: Trigonometry and Analytic Geometry": 1,
    "Math 241-Calculus I": 1,
    "Calculus A": 1,
    "PSYCH 100- Survey of Psychology": 1,
    "BOT 105 Ethnobotany": 1,
    "BOT 130 Plants in the Hawaiian Environment": 1,

    //Other

    "STEM Capstone": 1,
    "Year Book Production 1": 1,
    "Year Book Production 2": 1,
    "Year Book Production 3": 1,
    "Year Book Production 4": 1,
    "English Speaking Other Language 1A": 0.5,
    "English Speaking Other Language 1B": 0.5,
    "English Speaking Other Language 2A": 0.5,
    "English Speaking Other Language 2B": 0.5,
    "Leadership Training: Student Activity": 0.5,
    "Leadership Training: Student Body": 1
}

export default CourseCreditMapping;
// Base Url
export const BASE_URL = `${process.env.REACT_APP_URL}`;

// Headers

export const CONTENT_HEADERS = {
    "content-type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('userAccessToken'),
};


// API Endpoints

// Goal Details

export const GET_GOAL_DETAILS = `${BASE_URL}/users/percentage/`;

// get Details for Principal

export const GET_PRINCIPAL_DETAILS_URL = `${BASE_URL}/group/`;

export const GET_GOAL_DETAIL = `${BASE_URL}/users/percentage/group/`

export const GET_TEACHER_NAME = `${BASE_URL}/principal/teachers`
import React from 'react';
import "./Calendly.scss";
import { InlineWidget } from 'react-calendly';
import { PopupWidget } from "react-calendly";
import { PopupButton } from "react-calendly";


const CalendlyComponent = () => {

  const pageSettings={
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '#052941',
    textColor: '#052941'
  }
  

  // const prefill={
  //   email: 'test@test.com',
  //   firstName: 'Jon',
  //   lastName: 'Snow',
  //   name: 'Jon Snow',
  //   smsReminderNumber: '+1234567890',
  //   guests: [
  //     'janedoe@example.com',
  //     'johndoe@example.com'
  //   ],
  //   customAnswers: {
  //     a1: 'a1',
  //     a2: 'a2',
  //     a3: 'a3',
  //     a4: 'a4',
  //     a5: 'a5',
  //     a6: 'a6',
  //     a7: 'a7',
  //     a8: 'a8',
  //     a9: 'a9',
  //     a10: 'a10'
  //   },
  //   date: new Date(Date.now())
  // }


  return (
    <div className='calendly'>


      {/* <div>
        <InlineWidget url="https://calendly.com/laulama_contactus/30min" styles={{ height: '1000px' }} pageSettings={pageSettings}/>
      </div> */}

      {/* <div>
      <PopupWidget
        url="https://calendly.com/laulama_contactus/30min"
        rootElement={document.getElementById("root")}
        pageSettings={pageSettings}
        // prefill={prefill}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#00a2ff"
      />
      </div> */}


      <div>
        <PopupButton className='calendly-button'
          url="https://calendly.com/laulama_contactus/30min"
          rootElement={document.getElementById("root")}
          text="Schedule an appointment"
          pageSettings={pageSettings}
        />
      </div>

      <div>
      </div>


    </div>

  );
};

export default CalendlyComponent;

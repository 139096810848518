import React, { useEffect, useState } from 'react';
import '../Help.scss';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faList } from '@fortawesome/free-solid-svg-icons';
import SubCategory from '../Category/Category';
import NavBar from '../../StackNavBar/StackNavBar';

const Courses = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [userRole, setUserRoles] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Courses');
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/admin')) {
            handleAdminMenuClick(activeMenuItem);
            setUserRoles("Admin")
        } else if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRoles("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRoles("Teacher")
        } else if (currentUrl.includes('/student')) {
            handleStudentMenuClick(activeMenuItem);
            setUserRoles("Student")
        }

    }, [activeMenuItem]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handleAdminMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const handleStudentMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/student/dashboard');
                break;
            case "profile":
                navigate("/student/profile");
                break;
            default:
                break;
        }
    }
    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='help-section'>
                <div className='help-heading'>
                    <h3>Help Section</h3>
                </div>
                <div className='help-sub-section '>
                    <div className='m-5 flex flex-wrap'>
                        <div className='col-3 category-section border-right-3'>
                            <h3 className='mb-6'><b>By Category</b></h3>
                            <SubCategory selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick} />
                        </div>
                        <div className='col-9 guide-section-details  pl-5'>
                            {selectedCategory === 'Courses' && (
                                <div className='courses'>
                                    <h2 className='mb-6'>Courses</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faList} className='list' /><h3>Courses List</h3>
                                            </div>
                                            <div className='heading p-1'>High School Courses Information</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} />
                                                <a href="https://staticfile.laulamaschools.com/Kauia+Courses+List.pdf" target="_blank" download>Download PDF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Courses;

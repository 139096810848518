import React, { useEffect, useState } from 'react';
import '../Help-Section/Help.scss';
import { useNavigate } from 'react-router';
import NavBar from '../StackNavBar/StackNavBar';
import { LuPencilLine } from "react-icons/lu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFile, faFilePdf, faFilePen, faFileVideo, faFileWord, faFolderOpen, faList, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { LuAtom } from "react-icons/lu";

const Help = () => {
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [userRole, setUserRoles] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Laulama Guides');

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const currentUrl = window.location.pathname;

        if (currentUrl.includes('/admin')) {
            handleAdminMenuClick(activeMenuItem);
            setUserRoles("Admin")
        } else if (currentUrl.includes('/principal')) {
            handlePrincipalMenuClick(activeMenuItem);
            setUserRoles("Principal")
        } else if (currentUrl.includes('/teacher')) {
            handleTeacherMenuClick(activeMenuItem);
            setUserRoles("Teacher")
        } else if (currentUrl.includes('/student')) {
            handleStudentMenuClick(activeMenuItem);
            setUserRoles("Student")
        }

    }, [activeMenuItem]);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);
    };

    const handleAdminMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const handlePrincipalMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/principal/dashboard');
                break;
            case 'groups':
                navigate('/principal/groups');
                break;
            case 'students':
                navigate('/principal/students');
                break;
            default:
                break;
        }
    }

    const handleTeacherMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/teacher/dashboard');
                break;
            case 'students':
                navigate('/teacher/studentsList');
                break;
            default:
                break;
        }
    };

    const handleStudentMenuClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case 'dashboard':
                navigate('/student/dashboard');
                break;
            case "profile":
                navigate("/student/profile");
                break;
            default:
                break;
        }
    }


    return (
        <>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='help-section'>
                <div className='help-heading'>
                    <h3>Help Section</h3>
                </div>
                <div className='help-sub-section'>
                    <div className='flex flex-wrap m-5'>
                        <div className='col-3 category-section border-right-3'>
                            <h3 className='mb-6'><b>By Category</b></h3>
                            <div className='sub-category-details'>
                                {userRole === 'Admin' && (
                                    <p onClick={() => handleCategoryClick('Laulama Guides')} style={{ backgroundColor: selectedCategory === 'Laulama Guides' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Laulama Guides</p>
                                )}
                                {userRole === 'Principal' && (
                                    <>
                                        <p onClick={() => handleCategoryClick('Laulama Guides')} style={{ backgroundColor: selectedCategory === 'Laulama Guides' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Laulama Guides</p>
                                        <p onClick={() => handleCategoryClick('Personal Statement')} style={{ backgroundColor: selectedCategory === 'Personal Statement' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Personal Statement</p>
                                        <p onClick={() => handleCategoryClick('Courses')} style={{ backgroundColor: selectedCategory === 'Courses' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Courses</p>
                                        <p onClick={() => handleCategoryClick('Resume')} style={{ backgroundColor: selectedCategory === 'Resume' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Resume</p>
                                        <p onClick={() => handleCategoryClick('PTP Resources')} style={{ backgroundColor: selectedCategory === 'PTP Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>PTP Resources</p>
                                        <p onClick={() => handleCategoryClick('Help Resources')} style={{ backgroundColor: selectedCategory === 'Help Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>More Help Resources</p>
                                    </>
                                )}
                                {userRole === 'Teacher' && (
                                    <>
                                        <p onClick={() => handleCategoryClick('Laulama Guides')} style={{ backgroundColor: selectedCategory === 'Laulama Guides' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Laulama Guides</p>
                                        <p onClick={() => handleCategoryClick('Personal Statement')} style={{ backgroundColor: selectedCategory === 'Personal Statement' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Personal Statement</p>
                                        <p onClick={() => handleCategoryClick('Courses')} style={{ backgroundColor: selectedCategory === 'Courses' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Courses</p>
                                        <p onClick={() => handleCategoryClick('Resume')} style={{ backgroundColor: selectedCategory === 'Resume' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>Resume</p>
                                        <p onClick={() => handleCategoryClick('PTP Resources')} style={{ backgroundColor: selectedCategory === 'PTP Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>PTP Resources</p>
                                        <p onClick={() => handleCategoryClick('Help Resources')} style={{ backgroundColor: selectedCategory === 'Help Resources' ? '#91BF82' : 'transparent', padding: '0.5rem', borderRadius: "15px" }}>More Help Resources</p>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className='col-9 guides-section pl-5'>

                            {userRole === 'Admin' && selectedCategory === 'Laulama Guides' && (
                                <div className='guides'>
                                    <h2 className='mb-6'>Laulama Guides</h2>
                                    <div className="guides-collections">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuPencilLine /><h3>Admin Dashboard Guideline</h3>
                                            </div>
                                            <div className='heading p-1'>Clear instructions for using your admin dashboard effectively.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} />
                                             <a href="https://staticfile.laulamaschools.com/Admin+Dashboard+Guideline+V2.pdf" target='_blank' download> Download PDF</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'Laulama Guides' && (
                                <div className='guides'>
                                    <h2 className='mb-6'>Laulama Guides</h2>
                                    <div className="guides-collections">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuPencilLine /><h3>Principal Dashboard Guideline</h3>
                                            </div>
                                            <div className='heading p-1'>Clear instructions for using your principal dashboard effectively.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} /> 
                                            <a href="https://staticfile.laulamaschools.com/Principal+Dashboard+Guideline.pdf" target='_blank' download> Download PDF</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Teacher' && selectedCategory === 'Laulama Guides' && (
                                <div className='guides'>
                                    <h2 className='mb-6'>Laulama Guides</h2>
                                    <div className="guides-collections">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuPencilLine /><h3>Teacher Dashboard Guideline</h3>
                                            </div>
                                            <div className='heading p-1'>Clear instructions for using your teacher dashboard effectively.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} />
                                            <a href="https://staticfile.laulamaschools.com/Teacher+Dashboard+Guideline.pdf" target='_blank' download> Download PDF</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'Personal Statement' && (
                                <div className='personal'>
                                    <h2 className='mb-6'>Personal Statement</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFilePen} className='filepen' /><h3>Personal Statement Paragraph</h3>
                                            </div>
                                            <div className='heading p-1'>Your opportunity to share your unique story and aspirations.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} /> 
                                             <a href="https://staticfile.laulamaschools.com/Personal+Statement+Paragraph+Frame.pdf" target="_blank" download>Download PDF</a>
                                             </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom' /><h3>Your Science</h3>
                                            </div>
                                            <div className='heading p-1'>Video of how to LOG IN TO your science ACCOUNT</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                            <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/you-science" target="_blank">External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom-two' /><h3>Video: Your Science</h3>
                                            </div>
                                            <div className='heading p-1'>Summit Results Walkthrough</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://vimeo.com/347806606" target="_blank">Open Video</a>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Teacher' && selectedCategory === 'Personal Statement' && (
                                <div className='personal'>
                                    <h2 className='mb-6'>Personal Statement</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFilePen} className='filepen' /><h3>Personal Statement Paragraph</h3>
                                            </div>
                                            <div className='heading p-1'>Your opportunity to share your unique story and aspirations.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} /> 
                                             <a href="https://staticfile.laulamaschools.com/Personal+Statement+Paragraph+Frame.pdf" target="_blank" download>Download PDF</a>
                                             </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom' /><h3>Your Science</h3>
                                            </div>
                                            <div className='heading p-1'>Video of how to LOG IN TO your science ACCOUNT</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                            <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/you-science" target="_blank">External Link</a>
                                            </div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <LuAtom className='atom-two' /><h3>Video: Your Science</h3>
                                            </div>
                                            <div className='heading p-1'>Summit Results Walkthrough</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://vimeo.com/347806606" target="_blank">Open Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'Courses' && (
                                <div className='courses'>
                                    <h2 className='mb-6'>Courses</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faList} className='list' /><h3>Courses List</h3>
                                            </div>
                                            <div className='heading p-1'>High School Courses Information</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} />
                                                <a href="https://staticfile.laulamaschools.com/Kauia+Courses+List.pdf" target="_blank" download>Download PDF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Teacher' && selectedCategory === 'Courses' && (
                                <div className='courses'>
                                    <h2 className='mb-6'>Courses</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faList} className='list' /><h3>Courses List</h3>
                                            </div>
                                            <div className='heading p-1'>High School Courses Information</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFilePdf} />
                                                <a href="https://staticfile.laulamaschools.com/Kauia+Courses+List.pdf" target="_blank" download>Download PDF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'Resume' && (
                                <div className='resume'>
                                    <h2 className='mb-6'>Resume</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFile} className='file' /><h3>Resume Guide</h3>
                                            </div>
                                            <div className='heading p-1'>Crafting a professional summary of your achievements and skills.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                            <a href="https://drive.google.com/file/d/1XljFQ2ssj0cMMOsjRpH0_LWGUAc_qLOt/view?t=143" target="_blank">Open Video</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-1' /><h3>Resume Template Example</h3>
                                            </div>
                                            <div className='heading p-1'>Explore additional resume examples for inspiration.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://create.microsoft.com/en-us/template/restaurant-manager-resume-57cae682-222c-4646-9a80-c404ee5c5d7e" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-2' /><h3>Resume Template Example 2</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://create.microsoft.com/en-us/template/project-management-resume-5ff67a28-3747-45d8-ab40-465bf569783b" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-3' /><h3>Resume Template Example 3</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://create.microsoft.com/en-us/template/basic-sales-resume-7af668a8-d9d4-4a54-989e-422a9b87280f" target='_blank'>External Link</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Teacher' && selectedCategory === 'Resume' && (
                                <div className='resume'>
                                    <h2 className='mb-6'>Resume</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFile} className='file' /><h3>Resume Guide</h3>
                                            </div>
                                            <div className='heading p-1'>Crafting a professional summary of your achievements and skills.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} /> 
                                              <a href="https://drive.google.com/file/d/1XljFQ2ssj0cMMOsjRpH0_LWGUAc_qLOt/view?t=143" target="_blank">Open Video</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-1' /><h3>Resume Template Example</h3>
                                            </div>
                                            <div className='heading p-1'>Explore additional resume examples for inspiration.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} /> 
                                            <a href="https://create.microsoft.com/en-us/template/restaurant-manager-resume-57cae682-222c-4646-9a80-c404ee5c5d7e" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-2' /><h3>Resume Template Example 2</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                             <a href="https://create.microsoft.com/en-us/template/project-management-resume-5ff67a28-3747-45d8-ab40-465bf569783b" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faPaperclip} className='clip-3' /><h3>Resume Template Example 3</h3>
                                            </div>
                                            <div className='heading p-1'>Document showcasing examples.</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} /> 
                                            <a href="https://create.microsoft.com/en-us/template/basic-sales-resume-7af668a8-d9d4-4a54-989e-422a9b87280f" target='_blank'>External Link</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'PTP Resources' && (
                                <div className='resources'>
                                    <h2 className='mb-6'>PTP Resources</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-1' /><h3>Post-Secondary Plan</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                            <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/post-secondary-plan" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-2' /><h3>Post-Secondary Plan 2</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileWord} />Download Word</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {userRole === 'Teacher' && selectedCategory === 'PTP Resources' && (
                                <div className='resources'>
                                    <h2 className='mb-6'>PTP Resources</h2>
                                    <div className="guides-collections ">
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-1' /><h3>Post-Secondary Plan</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileVideo} />
                                            <a href="https://sites.google.com/k12.hi.us/class-of-2025-ptp/post-secondary-plan" target='_blank'>External Link</a></div>
                                        </div>
                                        <div className='guidesdetails'>
                                            <div className='img flex align-items-center justify-content-evenly gap-4'>
                                                <FontAwesomeIcon icon={faFolderOpen} className='open-2' /><h3>Post-Secondary Plan 2</h3>
                                            </div>
                                            <div className='heading p-1'>A description for this section</div>
                                            <div className="content flex align-items-center justify-content-start gap-4"><FontAwesomeIcon icon={faFileWord} />
                                            <a href="https://staticfile.laulamaschools.com/Post-Secondary+Plan+Draft+2024.docx" target="_blank" download>Download Word</a>   </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {userRole === 'Principal' && selectedCategory === 'Help Resources' && (
                                <div className='help_resources'>
                                    <h2 className='mb-6'>More Help Resources</h2>
                                    <div className="guides-collections ">
                                        <div className='img flex align-items-center justify-content-evenly gap-4'>
                                            <p>We will show here More Help Resources once we have more information.</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {userRole === 'Teacher' && selectedCategory === 'Help Resources' && (
                                <div className='help_resources'>
                                    <h2 className='mb-6'>More Help Resources</h2>
                                    <div className="guides-collections ">
                                        <div className='img flex align-items-center justify-content-evenly gap-4'>
                                            <p>We will show here More Help Resources once we have more information.</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Help;

import React from "react";
import "./Data-Analytics.scss";
import NewHeader from "../../Home/NewHeader/NewHeader";
import FooterSection from "../../Home/laulama-footer-section/FooterSection";
import DAHeaderImage from "../../../Assets/Products/Data-Analytics/DA-Header.svg";
import ChatScatter from "../../../Assets/Products/Data-Analytics/chart-scatter-solid.svg";
import ChatNetwork from "../../../Assets/Products/Data-Analytics/chart-network-solid.svg";
import ChatPie from "../../../Assets/Products/Data-Analytics/chart-pie-solid.svg";
import DisplayChart from "../../../Assets/Products/Data-Analytics/display-chart-up-solid.svg";
import Graph from "../../../Assets/Products/Data-Analytics/Using-grap.svg";

const DataAnalytics = () => {
  return (
    <>
      <NewHeader />
      <div className="dataanalytice">
        <div className="pp-header-section grid m-0">
          <div className="col-12 md:col-12 lg:col-6 pp-left">
            <div className="pp-header-content">
              <div className="m-h-title">Data Analytics</div>
              <p className="pp-h-p">
                From the school level down to individual students. Data is
                utilized in combination with modeling to help schools to:
              </p>
              <div class="formgrid grid gap-4">
                <div class=" da-cards field col flex align-items-center">
                  <div>
                    <img
                      className=""
                      src={ChatScatter}
                      alt="PSimage"
                    />
                  </div>
                  <div>Understand their performance</div>
                </div>
                <div class=" da-cards field col flex">
                  <div>
                    <img
                      className=""
                      src={ChatNetwork}
                      alt="PSimage"
                    />
                  </div>
                  <div>Diagnose problems</div>
                </div>
              </div>
              <div class="formgrid grid gap-4">
                <div class=" da-cards field col flex align-items-center">
                  <div>
                    <img
                      className=""
                      src={ChatPie}
                      alt="PSimage"
                    />
                  </div>
                  <div>Project future results</div>
                </div>
                <div class=" da-cards field col flex">
                  <div>
                    <img
                      className=""
                      src={DisplayChart}
                      alt="PSimage"
                    />
                  </div>
                  <div>Suggest alternative courses of action and rationale</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-12 lg:col-6 space da-img">
            <img className="da-h-image" src={DAHeaderImage} alt="" />
          </div>
        </div>

        <div className="da-content-section grid">
          <div className="col-12 md:col-12 lg:col-6 da-c-img pp-left-section">
            <img className="pp-c-image" src={Graph} alt="PSimage" />
          </div>
          <div className="col-12 md:col-12 lg:col-6">
            <div className="pp-header-content">
              <div className="pp-c-title">Using</div>
              <p className="pp-c-p">
                Basic graphs such as the Baseline Capacity Chart are created for
                each school. School specific analytics is also available.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default DataAnalytics;

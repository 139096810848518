import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ sectionId, maxCount , minCount}) => {
    const [count, setCount] = useState(minCount);
    const sectionRef = useRef(null);
  
    useEffect(() => {
      let animationFrameId;
  
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const updateCount = () => {
              setCount((prevCount) => (prevCount < maxCount ? prevCount + 1 : prevCount));
              if (count < maxCount) {
                animationFrameId = requestAnimationFrame(updateCount);
              }
            };
  
            updateCount();
          }
        });
      };
  
      const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        observer.disconnect();
        cancelAnimationFrame(animationFrameId);
      };
    }, [count, maxCount]);

  return (
    <div ref={sectionRef} id={sectionId}>
        <div>{count}</div>
    </div>
  );
};

export default Counter;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import '../../../Dashboard/MobileStudentForm/MobileStudentForm.scss';
import NavBar from '../../../../../Shared-Component/StackNavBar/StackNavBar';
import { Button } from 'primereact/button';
import PersonalStatementForm from '../../StudentForm/Personal-Statement-Form/PersonalStatementForm';


const MobilePersonalSatement = () => {

    const userRole = 'Student';
    const [activeMenuItem, setActiveMenuItem] = useState('dashboard');


    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

    };



    return (
        <div className="mob-personalstatement-section">

            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='m-5 '>
                <div className='mob-personal-link' >
                    <Link to="/student/dashboard"> <FaChevronLeft /> Back To Dashboard</Link>
                </div>
                {/* <div className='mob-personal-heading'>
                    <h3>My Personal Pathway</h3>
                </div> */}
                <div className='mob-personal-button mt-3' >
                    <Button className='Button'>1. Personal Statement </Button>
                </div>
                <PersonalStatementForm></PersonalStatementForm>
            </div>

        </div>
    );
};

export default MobilePersonalSatement;

import React, { useState, useEffect } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import '../../../../Student/Dashboard/StudentForm/Personal-Statement-Form/PersonalStatementForm.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import axios from 'axios';
import { AUTHORIZATION_HEADERS, PERSONAL_STATEMENT, GET_PERSONAL_STATEMENT, UPDATE_PERSONAL_STATEMENT, CONTENT_HEADERS } from '../../../../../Utils/StudentService';
import { useNavigate } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';

const PersonalStatementForm = ({ onNextButtonClick, personalStatementState }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [value, setValue] = useState('');
    const [personalvalue, setpersonalvalue] = useState('');

    const [personalStatement, setPersonalStatement] = useState('');
    const [mySkills, setMySkills] = useState([]);
    const [response, setResponse] = useState([]);
    const [displayedSkills, setDisplayedSkills] = useState([]);

    const [selectedSkills, setSelectedSkills] = useState([]);
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [autoskillsFilled, setAutoskillsFilled] = useState(false);

    const [getResponse, setGetResponse] = useState({});
    const [teacherNotes, setTeacherNotes] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [lastUpdatedOn, setLastUpdatedOn] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const skillsList = [
        'Logical', 'Solution-oriented', 'Methodical', 'Natural Planner', 'Future Focused', 'Determined',
        'Motivated', 'Brainstormer', 'Decisive', 'Diagnostic', 'Problem solver', 'Good Instincts',
        'Visual Mind', 'Leadership', 'Creativity', 'Idea Generator', 'Good Vocabulary', 'Good Communicator',
        'Improviser', 'Collaboration', 'Teamwork oriented', 'Critical thinking', 'Analyze quantitative data',
        'Professionalism or strong work ethic', 'Adaptability', 'Social media influencer', 'Content Creator'
    ];
    const navigate = useNavigate();


    useEffect(() => {
        fetchPersonalStatement();
    }, []);

    const fetchPersonalStatement = () => {
        axios
            .get(GET_PERSONAL_STATEMENT, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {

                setGetResponse(response.data);
                const latestTeacherNotes = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.notes;
                const latestTeacherName = response.data?.teacherNotes?.versions?.[response.data?.teacherNotes?.versions?.length - 1]?.teacherName;
                const updatedOn = response.data?.updatedOn ? new Date(response.data?.updatedOn).toLocaleDateString() : null;
                const createdOn = response.data?.createdOn ? new Date(response.data?.createdOn).toLocaleDateString() : null;

                setTeacherNotes(latestTeacherNotes);
                const teacherName = latestTeacherName;
                setTeacherName(teacherName);
                const lastUpdatedOn = updatedOn || createdOn;
                setLastUpdatedOn(lastUpdatedOn);
                const { personalStatement, mySkills } = response.data;
                const latestPersonalStatement = personalStatement.versions[personalStatement.versions.length - 1].personalStatement;
                const latestSkills = mySkills.versions[mySkills.versions.length - 1].mySkills;
                setPersonalStatement(latestPersonalStatement);
                setMySkills(latestSkills);
                setDisplayedSkills(latestSkills);
                setpersonalvalue(latestPersonalStatement);
                setFilteredSkills(latestSkills); 
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleSubmit = () => {
        const data = {
            personalStatement: personalvalue,
            mySkills: displayedSkills
        };
    
        axios
            .post(PERSONAL_STATEMENT, data, { headers: AUTHORIZATION_HEADERS })
            .then((response) => {
                const responseData = response.data;
    
                setResponse(responseData);
                setPersonalStatement(responseData.personalStatement);
                setMySkills(responseData.mySkills);
                setDisplayedSkills(responseData.mySkills);

              
                if (window.innerWidth > 1008) {
                    if ((tabState && buttonState) || (tabState === null && buttonState && !personalStatementState)) {
                        onNextButtonClick();
                    }
                } else {
                    navigate('/student/careergoals');
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });

            setIsDisabled(true);
    };

    const [tabState, setTabState] = useState(null);
    const [buttonState, setButtonState] = useState(false);
    
    useEffect(() => {
        if (!personalStatementState && !tabState) {
            setButtonState(true);
        }

        if (personalStatementState) {
            handleSubmit();
            setTabState(true);
        }
        
    }, [personalStatementState]);
    

    const searchSkills = (event) => {
        let query = event.query.toLowerCase();
        let filtered = skillsList.filter(skill => skill.toLowerCase().includes(query));
        filtered = filtered.filter(skill => !displayedSkills.includes(skill));
        if (filtered.length === 0) {
            filtered = ["No Results"];
        }
        setFilteredSkills(filtered);
    }

    const handleAdd = () => {
        if (displayedSkills.length < 6) {
        setSelectedSkills(prevSkills => [...prevSkills, value]);
        setDisplayedSkills(prevSkills => {
            const newSkills = prevSkills ? [...prevSkills, value] : [value];
            return newSkills;
        });
        setValue('');
        setAutoskillsFilled(false); 
    } else {
        alert('You can only add a maximum of 6 skills.');
    }
    };

    const handleDelete = (index) => {
        setDisplayedSkills(prevSkills => {
            if (!prevSkills) return [];
            const updatedSkills = [...prevSkills];
            updatedSkills.splice(index, 1);
            return updatedSkills;
        });
        setMySkills(prevSkills => {
            if (!prevSkills) return [];
            const updatedSkills = [...prevSkills];
            updatedSkills.splice(index, 1);
            return updatedSkills;
        });
    };
    

    const Chip = ({ label, onDelete }) => {
        return (
            <div className="chip">
                <span>{label}</span>
                <button onClick={onDelete}>x</button>
            </div>
        );
    };

    const handleAutoskillsChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        if (inputValue.trim() !== '') {
            setAutoskillsFilled(true);
        } else {
            setAutoskillsFilled(false);
        }
    };

    const accordionItems = [
        {
            title: "My Skills",
            content: (
                <div className="accordion-content">
                    <>
                        <p className='para-text'>Please provide your skills, aptitudes, or natural talents by selecting from the provided list. Begin by typing your skills, then choose the appropriate options from the list, and finally click "Add" to save your selections. Here is the
                            <a href="https://www.staticfile.laulamaschools.com/Skills+List.pdf" target="_blank" style={{ color: "#1067B8", cursor: "pointer" }}> list of all the skills</a> available for you to choose from.</p>
                        <span className='descrip'>Example: team work, collaborative</span>
                        <div className='flex auto-skills mt-3 w-full'>
                            <AutoComplete className="col-10 p-0 flex align-items-center mb-3 skills custom-placeholder"
                                value={value}
                                onChange={handleAutoskillsChange}
                                suggestions={filteredSkills}
                                completeMethod={searchSkills}
                                placeholder='Write down your skills here'
                                inputClassName="custom-placeholder" />
                            <Button className='flex align-items-center justify-content-center w-8rem h-3rem mb-3 add-button' label="Add" onClick={handleAdd} disabled={!autoskillsFilled} />
                        </div>
                        <div className='flex align-items-center justify-content-start flex-wrap gap-3'>
                            {displayedSkills?.length > 0 && displayedSkills.map((data, index) => (<Chip key={index} label={data} onDelete={() => handleDelete(index)} />))}
                        </div>
                    </>
                </div>
            )
        },
        {
            title: "My personal Statement",
            content: (
                <div className="accordion-content">
                    <>
                        <p className='para-text'>
                        A personal goal is a short paragraph at the top of your resume. It should work like an elevator speech: briefly describe your natural talents, skills, and goals. Learn more about how to do it
                         <a href="/student/help/personalStatement" style={{ color: "#1067B8", cursor: "pointer" }}> Personal Statement Paragraph</a>
                        </p>
                        <span className='descrip'>
                            Example: I’m a natural planner who is collaborative and future focused. I have an eye on the future, and I’m happiest when I’ve set a target that inspires me,
                            even years down the road. Five years from now, my goal is to have a B.S. in Business and to become an entrepreneur.
                        </span>

                        <div className="">
                            <InputTextarea className="flex align-items-center justify-content-center w-full h-8rem mt-3 custom-placeholder" keyfilter={/^[^<>*!]+$/} placeholder="Please write your personal goal" value={personalvalue} onChange={(e) => setpersonalvalue(e.target.value)} rows={5} cols={30} />
                        </div>
                    </>
                </div>
            )
        },
        {
            title: "Teacher Notes",
            content: (
                <div className="accordion-content">
                    <>
                    {teacherNotes ? (
                        <div className='teacher-notes-section m-5'>
                            <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                        </div>
                    ) : (
                        <div className='teacher-notes-section m-5'>
                            <p>There are no teacher notes.</p>
                        </div>
                    )}
                    </>
                </div>
            )
        }
    ];


    return (
        <div className='personal-section m-5'>
            <div className='desktop-personal'>
                <div>
                    <h3 className='head'>My Skills</h3>
                    <p className='para-text'>Please provide your skills, aptitudes, or natural talents by selecting from the provided list. Begin by typing your skills, then choose the appropriate options from the list, and finally click "Add" to save your selections. Here is the 
                        <a href="https://www.staticfile.laulamaschools.com/Skills+List.pdf" target="_blank" style={{ color: "#1067B8", cursor: "pointer" }}> list of all the skills</a> available for you to choose from.</p>
                    <span className='descrip'>Example: team work, collaborative</span>
                </div>
                <div className='flex auto-skills mt-3 w-full'>
                    <AutoComplete className="col-10 p-0 flex align-items-center mb-3 skills custom-placeholder"
                        value={value}
                        onChange={handleAutoskillsChange}
                        suggestions={filteredSkills}
                        completeMethod={searchSkills}
                        placeholder='Write down your skills here'
                        inputClassName="custom-placeholder" />
                    <Button className='flex align-items-center justify-content-center w-8rem h-3rem mb-3 add-button' label="Add" onClick={handleAdd} disabled={!autoskillsFilled} />
                </div>
                <div className='flex align-items-center justify-content-start flex-wrap gap-3'>
                    {displayedSkills?.length > 0 && displayedSkills.map((data, index) => (<Chip key={index} label={data} onDelete={() => handleDelete(index)} />))}
                </div>
                <div className='statement-section mb-5'>
                    <div>
                        <h3 className='head'>My personal Statement</h3>
                        <p className='para-text'>
                        A personal goal is a short paragraph at the top of your resume. It should work like an elevator speech: briefly describe your natural talents, skills, and goals. Learn more about how to do it
                         <a href="/student/help/personalStatement" style={{ color: "#1067B8", cursor: "pointer" }}> Personal Statement Paragraph</a>
                        </p>
                        <span className='descrip'>
                            Example: I’m a natural planner who is collaborative and future focused. I have an eye on the future, and I’m happiest when I’ve set a target that inspires me,
                            even years down the road. Five years from now, my goal is to have a B.S. in Business and to become an entrepreneur.
                        </span>

                        <div className="">
                            <InputTextarea className="flex align-items-center justify-content-center w-full h-8rem mt-3 custom-placeholder" keyfilter={/^[^<>*!]+$/} placeholder="Please write your personal goal" value={personalvalue} onChange={(e) => setpersonalvalue(e.target.value)} rows={5} cols={30} />
                        </div>

                    </div>
                </div>

                {teacherNotes && (
                    <div className='teacher-notes-section mt-5'>
                        <h3 className='head'>Teacher Notes</h3>
                        <p><b>{lastUpdatedOn} - </b>{teacherNotes} - ({teacherName})</p>
                    </div>
                )}
            </div>

            <div className='mobile-personal'>
                <Accordion activeIndex={activeIndex} onTabChange={(e) => toggleAccordion(e.index)}>
                    {accordionItems.map((item, index) => (
                         item.title === "Teacher Notes" && !teacherNotes ? null : (
                        <AccordionTab key={index} header={
                            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                <div className="header-text">{item.title}</div>
                                <div className="header-icon">
                                    <i className={`pi ${activeIndex === index ? "pi-minus" : "pi-plus"}`}></i>
                                </div>
                            </div>
                        }>
                            <div className="accordion-content">
                                {item.content}
                            </div>
                        </AccordionTab>
                         )
                    ))}
                </Accordion>
            </div>


            <footer className="footers-section flex justify-content-end">
                <Button label="Save and Continue" onClick={handleSubmit} disabled={isDisabled} />
            </footer>
        </div>

    );
};

export default PersonalStatementForm;

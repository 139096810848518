import React, { useState, useEffect } from 'react'
import "./Work-Internship.scss";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { Image } from 'primereact/image';
import moment from 'moment';


const WorkInternship = ({getApiResponse}) => {

   
    const [toggleForm, setToggleForm] = useState(false);
    const [newData, setNewData] = useState([]);
    const localStorageKey = 'WorkIntership';
    const [editIndex, setEditIndex] = useState(null);

    const data = {
        currentJob: false,
        jobTitle: '',
        company: '',
        startDate: '',
        endDate: '',
        description: '',
    };

    const [formData, setFormData] = useState(data);

    useEffect(() => {
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        if (getApiResponse?.experiences?.length > 0) {
          const response = getApiResponse?.experiences;
          const latestVersionNumber = ((response.length) - 1);
          const latestExperience = getApiResponse?.experiences[latestVersionNumber];
          if (latestExperience?.jobExperiances.length > 0) {
            const getExperience = latestExperience.jobExperiances.map((item) => {
                var startDateVal = item.startDate;
                const date = new Date(startDateVal);
                const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                const year = date.getUTCFullYear();
                const formattedStartDate =  `${month}/${year}`;
                if(item.currentJob) {
                    var formattedEndDate = null; 
                 } else {
                var endDateVal = item.endDate;
                const edate = new Date(endDateVal);
                const emonth = (edate.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                const eyear = edate.getUTCFullYear();
                var formattedEndDate =  `${emonth}/${eyear}`;
                 }
              return {
                currentJob: item?.currentJob,
                jobTitle: item?.jobTitle,
                company: item?.company,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                description: item?.description,
                    
              };
            });
            setNewData(getExperience);
            localStorage.setItem(localStorageKey, JSON.stringify(getExperience));
          }
        }
      }, [getApiResponse]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };

      const handleCheckboxChange = (e) => {
        setFormData({
          ...formData,
          currentJob: e.checked,
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        var selected_startyear = moment.utc(formData.startDate).format('MM/YYYY');
        if(formData.currentJob) {
            var selected_enddate = null;
        } else {
            var selected_enddate = moment.utc(formData.endDate).format('MM/YYYY');
        }
        const newTableData = {
            currentJob: formData.currentJob,
            jobTitle: formData.jobTitle,
            company: formData.company,
            startDate: selected_startyear,
            endDate: selected_enddate,
            description: formData.description,
        };

        if (editIndex !== null) {
            const updatedData = [...newData];
            var selected_startdate = moment.utc(formData.startDate).format('MM/YYYY');
                if(formData.currentJob) {
                    var selected_enddate = null;
                } else {
                    var selected_enddate = moment.utc(formData.endDate).format('MM/YYYY');
                }
                const editedData = {
                    currentJob: formData.currentJob,
                    jobTitle: formData.jobTitle,
                    company: formData.company,
                    startDate: selected_startdate,
                    endDate: selected_enddate,
                    description: formData.description,
                };
            updatedData[editIndex] = editedData;
            setNewData(updatedData);
            setEditIndex(null);
            setFormData(data);
            localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
          } else {

        const updatedFormData = [...newData, newTableData];
        
        setFormData(data);

        localStorage.setItem(localStorageKey, JSON.stringify(updatedFormData));
          }
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }

        handleClick();
      };

      const handleClick = () => {
        setEditIndex(null);
        setFormData(data);
        setToggleForm(!toggleForm);
      };
    
      const handleEdit = (rowData, index) => {
        setToggleForm(true);
        const startDateValue = rowData.startDate;
        const [month, year] = startDateValue.split('/');
        const formattedStartDate = new Date(`${month}/01/${year}`);
        if(rowData.currentJob) {
            var formattedEndDate = null;
        } else {
            const endDateValue = rowData.endDate;
            const [emonth, eyear] = endDateValue.split('/');
            var formattedEndDate = new Date(`${emonth}/01/${eyear}`);
        }
        setFormData({ ...rowData, startDate: formattedStartDate, endDate: formattedEndDate });
        setEditIndex(index);
      };
    
      const handleDelete = (index) => {
        const updatedData = [...newData];
        updatedData.splice(index, 1);
        setNewData(updatedData);
        setEditIndex(null);
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        setFormData(data);
      };

      const renderCell = (rowData, field) => {
        const value = rowData[field];
        return value !== null ? value : "Present Work";
      };

    return (
        <div className='extra-activities-section'>

            {toggleForm ?
                <div className='extra-heading'>
                    <div className='pt-1 pb-2'>Involve any job experience </div>
                    <form onSubmit={handleSubmit}>

                    <div className="flex flex-row align-items-center gap-2 pt-3 pb-3">
                        <Checkbox inputId="currentJob" checked={formData.currentJob} onChange={handleCheckboxChange} />
                        <label htmlFor="currentJob">Current Work</label>
                    </div>

                    <div className="flex flex-column gap-2 pt-2">
                        <label>Job Title</label>
                        <InputText name="jobTitle" value={formData.jobTitle} onChange={handleChange} required />
                    </div>

                    <div className="grid pt-4 mobile-work-field">
                        <div className="col lg:col-4 flex flex-column">
                            <label>Company</label>
                            <InputText name="company" value={formData.company} onChange={handleChange} required/>
                        </div>
                        <div className="col lg:col-4 flex flex-column date">
                            <label>Start Date</label>
                            <Calendar name="startDate" value={formData.startDate} onChange={handleChange} showIcon style={{ width: '700px' }} view="month" dateFormat="mm/yy" required/>
                        </div>
                        <div className="col lg:col-4 flex flex-column date">
                            <label>End Date</label>
                            <Calendar name="endDate" value={formData.endDate} onChange={handleChange} disabled={formData.currentJob} showIcon style={{ width: '700px' }} view="month" dateFormat="mm/yy" required/>
                        </div>
                    </div>

                        <div className="flex flex-wrap justify-content-between gap-3 pt-4 pb-4">
                            <div className="flex flex-column gap-2 w-full">
                                <label>Description</label>
                                <InputTextarea name="description" className='description w-full' value={formData.description} onChange={handleChange} rows={5} cols={120} required />
                            </div>
                        </div>

                    <footer className="Extra-activities-form-footer gap-4">
                    <Button label="Cancel" severity="info" onClick={handleClick} />
                    <Button type="submit" value="Submit" label={editIndex !== null ? "Update Work/Internship Experience" : "Add Work/Internship Experience"} severity="secondary" />
                    </footer>
                    </form>
                </div>
                :
                <div>
                    <div className="grid pt-4 pb-4 notes-alignment" onClick={handleClick}>
                        <div className='col lg:col-7'>Involve any job experience </div>
                        <div className="col lg:col-5 addextra-text">+ Add Work/Internship Experience</div>
                    </div>


                    {newData.length === 0 ? (
                        <div className="flex flex-column justify-content-center align-items-center">
                            <Image src={Illustration} alt="Image" className='extra-image' />
                            <p className='ex-p'>You will see information here when you add your Work Experience</p>
                            <Button className='m-3' label="Add Work/Internship Experiences" severity="secondary" onClick={handleClick}/>
                        </div>
                    ) : (
                        <div className="extra-activities-tables pt-4 pb-4">
                            <DataTable value={newData} tableStyle={{ minWidth: '50rem' }} className='datatable-container'>
                                <Column field="jobTitle" header="Job Title" />
                                <Column field="company" header="Company" />
                                <Column field="startDate" header="Start Date" />
                                <Column field="endDate" header="End Date" body={(rowData) => renderCell(rowData, 'endDate')} />
                                <Column field="description" header="Description" />
                                <Column
                                    header="Actions"
                                    body={(rowData, column) => (
                                    <div className="actions">
                                        <div>
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-success mr-1"
                                            onClick={() => handleEdit(rowData, column.rowIndex)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger ml-1"
                                            onClick={() => handleDelete(column.rowIndex)}
                                        />
                                        </div>
                                    </div>
                                    )}
                                />
                            </DataTable>

                            <ul className='hide-on-large-screens'>
                                {newData.map((item, index) => (
                                    <li key={item.id}>
                                        <p className='activity-heading'>{item.jobTitle}</p>
                                        <div className='px-4 py-4'>
                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >Company:</strong> {item.company}
                                            </div>

                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >Start Date:</strong>{item.startDate}
                                            </div>

                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >End Date:</strong> {item.endDate}
                                            </div>
                                            <div className='flex justify-content-evenly align-items-center'> 
                                            <strong >Description:</strong> {item.description}
                                            </div>
                                            <div className="actions p-4 flex justify-content-around align-items-center">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-success mr-1"
                                                    onClick={() => handleEdit(item, index)}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger ml-1"
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default WorkInternship
import React, { useState, useEffect } from "react";
import "./Template1.scss";
import { Button } from "primereact/button";
import html2pdf from "html2pdf.js";

const Template1 = ({ personalDatas }) => {

    const [name, setName] = useState([]);
    const [address, setAddress] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [email, setEmail] = useState([]);
    const [grade, setGrade] = useState([]);
    const [school, setSchool] = useState([]);
    const [gpa, setGPA] = useState([]);
    const [actScore, setACTScore] = useState([]);
    const [psatScore, setPSATScore] = useState([]);
    const [satScore, setSATScore] = useState([]);
    const [honor, setHonor] = useState([]);
    const [mySkills, setMySkills] = useState([]);
    const [PStatement, setPStatement] = useState([]);
    const [extraActivities, setExtraActivities] = useState([]);
    const [communityServices, setCommunityServices] = useState([]);
    const [workInternship, setWorkInternship] = useState([]);
    const [achievements, setAchievements] = useState([]);

    useEffect(() => {

        setName(personalDatas?.firstName + " " + personalDatas?.lastName);
        setAddress(personalDatas?.address?.addressLine1);
        setPhoneNumber(personalDatas?.phoneNumber);
        setEmail(personalDatas?.emailId);
        if (personalDatas && personalDatas.groups && personalDatas.groups.length > 0) {
            const grade = personalDatas?.groups[0]?.grade;
            setGrade(grade);
        }
        setSchool(personalDatas?.schools?.schoolName);
        setGPA(personalDatas?.highSchoolGap);

        setACTScore(personalDatas?.act_score);

        const data = personalDatas?.testScores;
        const honorGoals = personalDatas?.academicGoals;

        if (honorGoals?.academicGoals) {
            const latestVersion = ((honorGoals.academicGoals.length) - 1);
            const latestHonor = honorGoals.academicGoals[latestVersion];
            const honorRecognition = (latestHonor.goals).filter(item => item.academicGoalsCategoryName === "Honors Recognition Certificate Goal");
            setHonor(honorRecognition[0]?.goalOptions);
        }

        if (data?.actScores) {
            const latestVersion = ((data.actScores.actScoresVersion.length) - 1);
            const latestActScores = data.actScores.actScoresVersion[latestVersion];
            setACTScore("Composite: " + latestActScores?.actScores[0]?.composite);
        }

        if ((data?.psatScores)) {
            const latestVersion = ((data.psatScores.scoresVersion.length) - 1);
            const latestPSATScores = data.psatScores.scoresVersion[latestVersion];
            setPSATScore(latestPSATScores?.scores[0]?.total);
        }

        if ((data?.satScores)) {
            const latestVersion = ((data.satScores.scoresVersion.length) - 1);
            const latestActScores = data.satScores.scoresVersion[latestVersion];
            setSATScore(latestActScores?.scores[0]?.total);
        }

        if (personalDatas?.personalStatement?.mySkills?.versions?.length > 0) {
            const mySkills = personalDatas.personalStatement.mySkills;
            const latestSkills = mySkills.versions[mySkills.versions.length - 1];
            setMySkills(latestSkills?.mySkills);
        }

        if (personalDatas?.personalStatement?.personalStatement?.versions?.length > 0) {
            const personalStatement = personalDatas.personalStatement.personalStatement;
            const latestPersonalStatement = personalStatement.versions[personalStatement.versions.length - 1];
            setPStatement(latestPersonalStatement);
        }

        if (personalDatas?.extraActivitiesDTO?.extraCurricularActivities?.length > 0) {
            const response = personalDatas?.extraActivitiesDTO?.extraCurricularActivities;
            const latestVersionNumber = ((response.length) - 1);
            const latestExtraCurricularActivities = personalDatas?.extraActivitiesDTO?.extraCurricularActivities[latestVersionNumber];
            if (latestExtraCurricularActivities?.activities.length > 0) {
                const getExtraCurricularActivities = latestExtraCurricularActivities.activities.map((item) => {
                    var startDateVal = item.startDate;
                    const date = new Date(startDateVal);
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
                    const year = date.getUTCFullYear();
                    const formattedStartDate = `${month}/${year}`;
                    if (item.currentActivity) {
                        var formattedEndDate = null;
                    } else {
                        var endDateVal = item.endDate;
                        const edate = new Date(endDateVal);
                        const emonth = (edate.getUTCMonth() + 1).toString().padStart(2, '0');
                        const eyear = edate.getUTCFullYear();
                        var formattedEndDate = `${emonth}/${eyear}`;
                    }
                    return {
                        currentActivity: item?.currentActivity,
                        extraCurricularActivity: item?.extraCurricularActivity,
                        positionHeld: item?.positionHeld,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate || 'Present Activity',
                        description: item?.description,
                    };
                });
                setExtraActivities(getExtraCurricularActivities);
            }
        }

        if (personalDatas?.extraActivitiesDTO?.communityServices?.length > 0) {
            const response = personalDatas?.extraActivitiesDTO?.communityServices;
            const latestVersionNumber = ((response.length) - 1);
            const latestCommunityServices = personalDatas?.extraActivitiesDTO?.communityServices[latestVersionNumber];
            if (latestCommunityServices?.services.length > 0) {
                const getCommunityServices = latestCommunityServices.services.map((item) => {
                    var startDateVal = item.startDate;
                    const date = new Date(startDateVal);
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                    const year = date.getUTCFullYear();
                    const formattedStartDate = `${month}/${year}`;
                    if (item.currentService) {
                        var formattedEndDate = null;
                    } else {
                        var endDateVal = item.endDate;
                        const edate = new Date(endDateVal);
                        const emonth = (edate.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                        const eyear = edate.getUTCFullYear();
                        var formattedEndDate = `${emonth}/${eyear}`;
                    }
                    return {
                        currentService: item?.currentService,
                        communityService: item?.communityService,
                        place: item?.place,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                        totalHours: item?.totalHours,
                        description: item?.description,
                    };
                });
                setCommunityServices(getCommunityServices);

            }
        }

        if (personalDatas?.extraActivitiesDTO?.experiences?.length > 0) {
            const response = personalDatas?.extraActivitiesDTO?.experiences;
            const latestVersionNumber = ((response.length) - 1);
            const latestExperience = personalDatas?.extraActivitiesDTO?.experiences[latestVersionNumber];
            if (latestExperience?.jobExperiances.length > 0) {
                const getExperience = latestExperience.jobExperiances.map((item) => {
                    var startDateVal = item.startDate;
                    const date = new Date(startDateVal);
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                    const year = date.getUTCFullYear();
                    const formattedStartDate = `${month}/${year}`;
                    if (item.currentJob) {
                        var formattedEndDate = null;
                    } else {
                        var endDateVal = item.endDate;
                        const edate = new Date(endDateVal);
                        const emonth = (edate.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
                        const eyear = edate.getUTCFullYear();
                        var formattedEndDate = `${emonth}/${eyear}`;
                    }
                    return {
                        currentJob: item?.currentJob,
                        jobTitle: item?.jobTitle,
                        company: item?.company,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate || 'Present Activity',
                        description: item?.description,
                    };
                });
                setWorkInternship(getExperience);
            }
        }

        if (personalDatas?.extraActivitiesDTO?.achievements?.length > 0) {
            const response = personalDatas?.extraActivitiesDTO?.achievements;
            const latestVersionNumber = ((response.length) - 1);
            const latestAchievement = personalDatas?.extraActivitiesDTO?.achievements[latestVersionNumber];
            if (latestAchievement?.achievement.length > 0) {
                const getAchievements = latestAchievement.achievement.map((item) => {
                    var val = item.date;
                    const [year, month, day] = val.split('-');
                    // Format the date as mm/yyyy
                    const formattedDate = `${month}/${year}`;
                    return {
                        achievement: item?.achievement,
                        date: formattedDate,
                        description: item?.description,
                    };
                });
                setAchievements(getAchievements);
            }
        }

    }, [personalDatas]);

    const downloadPdf = () => {
        const element = document.getElementById("template1-content");
        html2pdf(element, {
            margin: 0.5,
            filename: (personalDatas?.firstName + "_" + personalDatas?.lastName) + '_' + 'Resume.pdf',
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        });
    };

    return (
        <div className="t1-template">
            <div id="template1-content" className="resumet1">
                <div className="grid t1-template-content">
                    <div className="col lg:col-5 t1-left">
                        <div className="t1-name"><b>{name}</b></div>
                        <div className="t1personal-details">
                            {personalDatas?.address?.addressLine1 && (
                                <div className="t1l-details">{address},</div>
                            )}
                            <div className="t1l-details">{phoneNumber}</div>
                            <div className="t1l-details">{email}</div>
                        </div>

                        <div className="t1l-label">Education</div>
                        <div className="t1l-details">{school}</div>
                        <div className="t1l-label">Class:<span className="t1l-details">&nbsp;{grade}</span></div>
                        {personalDatas?.highSchoolGap && (
                            <div className="t1l-label">GPA:{gpa}</div>
                        )}
                        {personalDatas?.testScores && (
                            <div>
                                <div className="t1l-label">Test Scores:</div>
                                {personalDatas?.testScores?.actScores && (
                                    <div className="t1l-details">Act Score&nbsp;{actScore}</div>
                                )}
                                {personalDatas?.testScores?.psatScores && (
                                    <div className="t1l-details">PSAT Score:&nbsp;{psatScore}</div>
                                )}
                                {personalDatas?.testScores?.satScores && (
                                    <div className="t1l-details">SAT Score:&nbsp;{satScore}</div>
                                )}
                            </div>
                        )}
                        {personalDatas?.academicGoals?.academicGoals && (
                           <>
                            <div className="t1l-label">Honors Recognition Certificate Goals:
                                </div>
                                <span className="t1l-details">{honor}</span>
                           </>
                        )}
                        {personalDatas?.personalStatement?.mySkills && (
                            <div>
                                <div className="t1l-label">Skills:</div>
                                {mySkills.map((item, index) => (
                                    <li className="t1l-details" key={index}>{item}</li>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="col lg:col-7 t1-right">
                        {personalDatas?.personalStatement?.personalStatement && (
                            <div className="t1r-sections">
                                <div className="t1r-label">Personal Statement</div>
                                <div className="t1r-content">{PStatement.personalStatement}</div>
                            </div>
                        )}

                        {personalDatas?.extraActivitiesDTO?.extraCurricularActivities && (
                            <div className="t1r-sections">
                                <div className="t1r-label">
                                    Extracurricular Activities & Athletics
                                </div>
                                {extraActivities.map((extraActivity, index) => (
                                    <div key={index}>
                                        <div className="t1r-content">
                                            <div className="t1r-subcontent">
                                                <div className="t1r-content">{extraActivity.extraCurricularActivity}</div>
                                                <div className="t1r-content">{extraActivity.startDate}-{extraActivity.endDate}</div>
                                            </div>
                                            <div className="t1r-content">{extraActivity.positionHeld}</div>
                                            <div className="t1r-content">{extraActivity.description}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {personalDatas?.extraActivitiesDTO?.communityServices && (
                            <div className="t1r-sections">
                                <div className="t1r-label">Community Service</div>
                                {communityServices.map((communityService, index) => (
                                    <div key={index}>
                                        <div className="t1r-content">
                                            <div className="t1r-subcontent">
                                                <div className="t1r-content">{communityService ? (communityService.communityService) : 'N/A'}</div>
                                                <div className="t1r-content">{communityService.totalHours + " "}hours</div>
                                            </div>
                                            <div className="t1r-content">{communityService.description}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {personalDatas?.extraActivitiesDTO?.experiences && (
                        <div className="t1r-sections">
                            <div className="t1r-label">Work/Internship Experience</div>
                            {workInternship.map((work, index) => (
                                <div key={index}>
                                    <div className="t1r-content">
                                        <div className="t1r-subcontent">
                                            <div className="t1r-content">{work.jobTitle}</div>
                                            <div className="t1r-content">{work.startDate}-{work.endDate}</div>
                                        </div>
                                        <div className="t1r-content">{work.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}

                        {personalDatas?.extraActivitiesDTO?.achievements && (
                        <div className="t1r-sections">
                            <div className="t1r-label">Achievements</div>
                            {achievements.map((achievement, index) => (
                                <div key={index}>
                                    <div className="t1r-content">
                                        <div className="t1r-subcontent">
                                            <div className="t1r-content">{achievement.achievement}</div>
                                            <div className="t1r-content">{achievement.date}</div>
                                        </div>
                                        <div className="t1r-content">{achievement.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}

                    </div>
                </div>
            </div>
            <div className="pdf-button">
                <Button
                    label="Use this template"
                    onClick={() => {
                        downloadPdf();
                    }}
                />
            </div>
        </div>
    );
};

export default Template1;

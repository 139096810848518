import React, { useEffect, useState } from 'react';
import NavBar from '../../../Shared-Component/StackNavBar/StackNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { CREATE_USER_URL, GET_SCHOOL_URL, UPDATE_USER_URL } from '../../../Utils/AdminService';
import { CONTENT_HEADERS } from '../../../Utils/LoginService';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';

const CreateForm = () => {
    const userRole = "Admin";
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState("teachers");

    const [isEditMode, setIsEditMode] = useState(false);

    const [schoolOptions, setSchoolOptions] = useState([]);

    const location = useLocation();
    const { rowData } = location.state || {};

    const [formData, setFormData] = useState({
        schoolId: "",
        emailId: "",
        firstName: "",
        phoneNumber: "",
        contactType: "",
        middleName: "",
        lastName: "",
        userType: "",
        schoolName: "",
        gender: "",
    });

    const [formErrors, setFormErrors] = useState({
        schoolId: "",
        firstName: "",
        lastName: "",
        emailId: "",
        gender: ""
    });

    const contactTypeOptions = ['Mobile', 'Phone'];

    const genderOptions = ["Male", "Female", "Other"];

    const [openDialog, setOpenDialog] = useState(false);

    const handleMenuItemClick = (item) => {
        setActiveMenuItem(item);

        switch (item) {
            case "dashboard":
                navigate("/admin/dashboard");
                break;
            case "principals":
                navigate("/admin/principals");
                break;
            case "teachers":
                navigate("/admin/teachers");
                break;
            case "groups":
                navigate("/admin/groups");
                break;
            case "students":
                navigate("/admin/students");
                break;
            default:
                break;
        }
    };

    const editedData = {
        userId: rowData?.userId,
        schoolId: rowData?.registrationId,
        emailId: rowData?.emailId,
        firstName: rowData?.firstName,
        phoneNumber: rowData?.phoneNumber?.replace(/^1/, ''),
        contactType: rowData?.contactType,
        middleName: rowData?.middleName,
        lastName: rowData?.lastName,
        userType: rowData?.userType,
        schoolName: rowData?.schools?.schoolName,
        gender: rowData?.gender
    };

    useEffect(() => {
        fetchSchool();
        // setOpenDialog(true);
        if (rowData) {
            setIsEditMode(true);
            setFormData(editedData);
        } else {
            setIsEditMode(false);
            setFormData({
                schoolId: "",
                emailId: "",
                firstName: "",
                phoneNumber: "",
                contactType: "",
                middleName: "",
                lastName: "",
                userType: "",
                schoolName: "",
                gender: ""
            });
        }
    }, [rowData]);

    const fetchSchool = () => {
        axios
            .get(GET_SCHOOL_URL, { headers: CONTENT_HEADERS })
            .then((response) => {
                setSchoolOptions(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCreateGroup = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setFormErrors(errors);

        if (Object.values(errors).some(error => error !== "")) {
            return;
        }

        if (isEditMode) {
            handleEdit(formData);
        } else {
            handleAdd(formData);
        }
    };

    const handleAdd = (newGroup) => {
        const schoolName = localStorage.getItem('schoolName');
        newGroup.userType = "Teacher";
        newGroup.schoolName = schoolName;
        axios
            .post(CREATE_USER_URL, newGroup, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate("/admin/teachers")
            }
            )
            .catch((error) => {
                console.error('API Error:', error);
            });
    }

    const handleEdit = (editedData) => {
        axios
            .put(UPDATE_USER_URL, editedData, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate("/admin/teachers")
            })
            .catch((error) => {
                console.log("API Error:", error)
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value.trim(),
        }));
        if (value.trim() === "") {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }

        if (event.target.name === "emailId") {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(event.target.value)) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    emailId: "Please enter a valid email address",
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    emailId: "",
                }));
            }
        }
    };

    const hideOpenDialog = () => {
        setOpenDialog(false);
    }

    const openDialogFooter = (
        <React.Fragment>
            <Button label="Accept and Close" severity="danger" onClick={hideOpenDialog} />
        </React.Fragment>
    );

    const validateForm = (data) => {
        let errors = {
            schoolId: "",
            firstName: "",
            lastName: "",
            emailId: "",
            gender: ""
        };

        if (!data.schoolId) {
            errors.schoolId = "School ID is required";
        }
        if (!data.firstName) {
            errors.firstName = "First Name is required";
        }
        if (!data.lastName) {
            errors.lastName = "Last Name  is required";
        }
        if (!data.emailId) {
            errors.emailId = "Email is required";
        }
        if (!data.gender) {
            errors.gender = "Gender is required";
        }

        return errors;
    };


    return (
        <div>
            <NavBar
                activeMenuItem={activeMenuItem}
                onMenuItemClick={handleMenuItemClick}
                userRole={userRole}
            />
            <div className='form-section'>
                <Link
                    to="/admin/teachers"
                    className="textdecor"
                >
                    <FaChevronLeft />
                    Back to Teachers
                </Link>
            </div>
            <div className='mx-6'>
                <Card className="flex flex-column justify-content-center items-center mt-2 mx-4">
                    <div className="grid justify-content-start mx-4 align-items-center">
                        <h2 className="flex justify-content-center w-full">Laulama Teacher Registration</h2>
                    </div>

                    <div className='mx-4'>
                        <form className="formgrid" onSubmit={handleCreateGroup}>
                            <div className="header flex justify-content-start mx-3 w-full">
                                <h2>{isEditMode ? "Edit Information" : "Add a new Teacher"}</h2>
                            </div>
                            <div className="flex col">
                                <div className="field col-4">
                                    <label>Teacher School ID</label>
                                    <InputText
                                        name="schoolId"
                                        value={formData.schoolId}
                                        onChange={handleChange}
                                        placeholder='ID'
                                        className="p-inputtext-sm w-full"
                                    />
                                    {formErrors.schoolId && <small className="text-danger" style={{color:"red"}}>{formErrors.schoolId}</small>}
                                </div>
                                <div className="field col-4">
                                    <label>School Name</label>
                                    <InputText
                                        name="schoolName"
                                        value={localStorage.getItem('schoolName')}
                                        onChange={handleChange}
                                        placeholder="Enter School Name"
                                        className="w-full"
                                    />
                                </div>
                                <div className="field col-4">
                                    <label>Role</label>
                                    <InputText
                                        name="userType"
                                        value="Teacher"
                                        onChange={handleChange}
                                        placeholder='Enter the role'
                                        className="p-inputtext-sm w-full"
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="flex col">
                                <div className="field col-4">
                                    <label>Name</label>
                                    <InputText
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder='Name'
                                        className="p-inputtext-sm w-full"
                                    />
                                 {formErrors.firstName && <small className="text-danger" style={{color:"red"}}>{formErrors.firstName}</small>}
                                </div>
                                <div className="field col-4">
                                    <label>Middle Name (Optional)</label>
                                    <InputText
                                        name="middleName"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                        placeholder='Middle Name'
                                        className="p-inputtext-sm w-full"
                                    />
                                </div>
                                <div className="field col-4">
                                    <label>Last Name</label>
                                    <InputText
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder='Last Name'
                                        className="p-inputtext-sm w-full"
                                    />
                                    {formErrors.lastName && <small className="text-danger" style={{color:"red"}}>{formErrors.lastName}</small>}
                                </div>
                            </div>

                            <div className="flex col">
                                <div className="field col">
                                    <label>Email</label>
                                    <InputText
                                        name="emailId"
                                        value={formData.emailId}
                                        onChange={handleChange}
                                        placeholder='Email'
                                        className="p-inputtext-sm w-full"
                                    />
                                    {formErrors.emailId && <small className="text-danger" style={{color:"red"}}>{formErrors.emailId}</small>}
                                </div>
                                <div className="field col-4">
                                    <label>Select Gender</label>
                                    <Dropdown
                                        name="gender"
                                        options={genderOptions}
                                        value={formData.gender  === "F" ? "Female" : formData.gender  === "M" ? "Male" : formData.gender}
                                        onChange={handleChange}
                                        placeholder="Select a Gender"
                                        className="w-full"
                                    />
                                    {formErrors.gender && <small className="text-danger" style={{color:"red"}}>{formErrors.gender}</small>}
                                </div>
                            </div>

                            <div className="flex col">
                                <div className="field col-8">
                                    <label>Phone Number (Optional)</label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        placeholder="(999) 999-9999"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full">
                                    </InputMask>
                                </div>
                                <div className="field col-4">
                                    <label>Select Type (Optional)</label>
                                    <Dropdown
                                        name="contactType"
                                        options={contactTypeOptions}
                                        value={formData.contactType}
                                        onChange={handleChange}
                                        placeholder="Select Type"
                                        className="w-full"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-content-end m-3 gap-4 align-items-center">
                                <Link
                                    to="/admin/teachers"
                                    className="textdecor-button"
                                    style={{ textDecoration: 'none' }}
                                >
                                    Cancel
                                </Link>
                                <Button
                                    label={isEditMode ? "Update" : "Confirm"}
                                    className="p-button-success"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                </Card>
            </div>

            <div className="send-invite-dialog">
                <Dialog visible={openDialog} style={{ width: '32rem', fontSize: '1.5rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    modal footer={openDialogFooter}
                    onHide={hideOpenDialog}
                >
                    <div className="confirmation-content  text-center">
                        <i className="pi pi-check-circle mr-3" style={{ fontSize: '2.5rem', color: '#91BF82' }} />
                        <h2 style={{ fontSize: '1.5rem' }}>Invitation has been send</h2>
                        <span style={{ fontSize: '1.2rem' }}>
                            Wait for this person complete the registration
                        </span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default CreateForm;

import React, { useState } from "react";
import "./Subscription.scss";
import { Card } from "primereact/card";
import { FaCheck } from "react-icons/fa";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ScrollTop } from "primereact/scrolltop";
import check from "../../Assets/Subscription/check.svg";
import cross from "../../Assets/Subscription/cross.svg";
import NewHeader from "../Home/NewHeader/NewHeader";
import FooterSection from "../Home/laulama-footer-section/FooterSection";

const Subscription = () => {
  const initialRowCount = 3;
  const [rows, setRows] = useState(initialRowCount);
  const Icon1 = ({ src, alt }) => (
    <img
      src={check}
      alt={alt}
      style={{ width: "30px", height: "30px", marginRight: "5px" }}
    />
  );
  const Icon2 = ({ src, alt }) => (
    <img
      src={cross}
      alt={alt}
      style={{ width: "30px", height: "30px", marginRight: "5px" }}
    />
  );

  const data = [
    {
      features: " Reference Materials",
      subscriptionModel:
        " Access to Laulama School’s Improvement System training and reference materials, including the Laulama Schools Network contact list.",
        licensingModel:
        "NA",
    },
    {
      features: "Modeling",
      subscriptionModel:
        "Access to Laulama School’s library of School Design Visual Frameworks.",
        licensingModel:
        "NA",
    },
    {
      features: "Data Analytics",
      subscriptionModel:
        "School specific baseline capacity, grade level variation and classroom trending charts. Schools are responsible for providing the data to Laulama Schools.",
        licensingModel:
        "NA",
    },
    {
      features: "Consultancy",
      subscriptionModel: "Access to 3 hours of consultancy or training. Sessions will be held via video conference. In person sessions are welcomed, travel expenses will be additional.",
      licensingModel: "NA",
    },
  ];

  const columns = [
    { field: "features", header: "Features" },
    { field: "subscriptionModel", header: "Subscription Model" },
    { field: "licensingModel", header: "Licensing Model" },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column key={col.field} field={col.field} header={col.header} />
  ));

  const renderRows = () => {
    return data.slice(0, rows);
  };

  return (
    <>
      <ScrollTop />
      <NewHeader />
      <div className="subscription">
        <div>
          <div className="subscription-header flex align-items-center justify-content-evenly">
            <div className="align-items-center justify-content-center">
              <div className="s-header-text">Choose the best plan for you</div>
              <div className="s-header-subtext">
                See our complete services package
              </div>
            </div>
          </div>
        </div>
        <div className="subscription-packages">
          <div className="flex justify-content-center flex-wrap">
            <div className="subscription-gold-card flex justify-content-center">
              <Card
                title="Subscription Model"
                subTitle="$3,000"
                className="subscription-card"
              >
                <div className="m-0">
                  <div className="descriptions">
                    Schools pay a recurring fee for access to the platform,
                    including features like the student portal, administrative
                    tools, and course digitization.
                  </div>
                  {/* <div className="l-card-button">
                    <Button label="Learn more" severity="success" />
                  </div> */}
                </div>
              </Card>
            </div>
            <div className="subscription-platinum-card flex justify-content-center">
              <Card
                title="Licensing Model"
                subTitle="$0000"
                className="subscription-card"
              >
                <div className="m-0">
                  <div className="descriptions">
                    Under the licensing model, Laulama can license its platform
                    to schools or DOE, which can then host and customize it on
                    their servers. DOW or Schools pay an upfront fee for the
                    license and may also pay for maintenance and support.
                  </div>
                  {/* <div className="r-card-button">
                    <Button label="Learn more" severity="Primary" />
                  </div> */}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="subscription-features justify-content-center">
          <div className="subscription-table">
            <DataTable value={renderRows()} responsive="true">
              {dynamicColumns}
            </DataTable>
            <div className="view-btn-section flex justify-content-center">
              {rows <= initialRowCount && (
                <button
                  className="view-button"
                  onClick={() => setRows((prevRows) => prevRows + 6)}
                  disabled={rows >= data.length}
                >
                  View More
                </button>
              )}
              {rows > initialRowCount && (
                <button
                  className="view-button"
                  onClick={() => setRows(initialRowCount)}
                >
                  View Less
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default Subscription;

import React, { useState, useEffect } from 'react';
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';
import './Testimonials.scss';
import NewHeader from '../Home/NewHeader/NewHeader';

// Import images
import image1 from '../../Assets/Avatar/StudentProfile.png'
import image2 from '../../Assets/Avatar/StudentProfile.png';
import image3 from '../../Assets/Avatar/StudentProfile.png';
import image4 from '../../Assets/Avatar/StudentProfile.png';
import image5 from '../../Assets/Avatar/StudentProfile.png';
import image6 from '../../Assets/Avatar/StudentProfile.png';
import FooterSection from '../Home/laulama-footer-section/FooterSection';



const initialTestimonialsData = [

  {
    name: 'Corey Nakamura',
    role: 'Principal',
    rating: 5,
    title: 'Fantastic Experience',

    message: 'This is different, something I never learned in my entire career in education.',
    videoUrl: 'https://www.youtube.com/embed/CGRw_M0uC4A?feature=shared',
    imageUrl: image1
  },
  {
    name: 'Various',
    role: 'Principals',
    rating: 5,
    title: 'Great service and support!',
    message: "These tools will change the way we plan for next year, we also can't wait for Laulama Learning.",
    videoUrl: 'https://www.youtube.com/embed/wi-L_TtsqC4?feature=shared',
    imageUrl: image2
  },
  {
    name: 'Leila Kobayashi',
    role: 'Principal',
    rating: 5,
    title: 'Very insightful and helpful!',
    message: 'By attending the Conference, I now have a better understanding of Laulama and what we have been doing this past year.',
    videoUrl: 'https://www.youtube.com/embed/KJSD6LFErgE?feature=shared',
    imageUrl: image3
  },
  {
    name: 'Tayler Kanoho',
    role: 'Teacher',
    rating: 5,
    title: 'The best experience!',
    message: 'I now know what my issue was that caused the results for my class and what I need to change to improve.',
    videoUrl: 'https://www.youtube.com/embed/GZzFoBpNL4k?feature=shared',
    imageUrl: image4
  },
  {
    name: 'Leila Kobayashi',
    role: 'Principal',
    rating: 5,
    title: 'Very good experience!',
    message: 'We have to continue for the sake of the schools staff.',
    videoUrl: 'https://www.youtube.com/embed/kGvVG3hjpTU?feature=shared',
    imageUrl: image5
  },
  {
    name: 'Corey Nakamura',
    role: 'Principal',
    rating: 5,
    title: 'Very useful and helpful!',
    message: 'The good thing is that what we learn at one school can be used everywhere, it eliminates duplication.',
    videoUrl: 'https://www.youtube.com/embed/VzhZ5e1kq50?feature=shared',
    imageUrl: image6
  },
];

const sortOptions = [
  { label: 'Highest Rated', value: 'highest' },
  { label: 'Lowest Rated', value: 'lowest' },
  { label: 'Most Recent', value: 'recent' },
  // ... other sort options
];

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState(initialTestimonialsData);
  const [selectedTestimonial, setSelectedTestimonial] = useState(initialTestimonialsData[0]);
  const [sortKey, setSortKey] = useState('recent');
  const [selectedDiv, setSelectedDiv] = useState(0);

  const handleSortChange = (e) => {

    setSortKey(e.value);

    setSortKey(e.value);
    switch (e.value) {
      case 'highest':
        setTestimonials([...initialTestimonialsData].sort((a, b) => b.rating - a.rating));
        break;
      case 'lowest':
        setTestimonials([...initialTestimonialsData].sort((a, b) => a.rating - b.rating));
        break;
      case 'recent':
      default:
        setTestimonials([...initialTestimonialsData]);
        break;
    }
  };

  const handleTestimonialClick = (testimonial, index) => {
    setSelectedTestimonial(testimonial);
    setSelectedDiv(index);
  };

  return (
    <>
      <NewHeader />
      <div className="testimonials-section">
        <div className="testimonials-header">
          <h1>Testimonials</h1>
          <Dropdown value={sortKey} options={sortOptions} onChange={handleSortChange} placeholder="Sort by" />
        </div>
        {/* <div className="testimonials-content">
            <div className="testimonial-list">
              {testimonials.map((testimonial, index) => (
                <div key={index} className={`highlightable-div ${selectedDiv === index ? 'highlighted' : ''}`} onClick={() => handleTestimonialClick(testimonial, index)}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center", padding: "10px" }}>
                    <div className="testimonial-image" >
                      <img src={testimonial.imageUrl} alt={`${testimonial.name}`} width={50} />
                    </div>
                    <div className="testimonial-info" style={{ paddingLeft: "20px" }}>
                      <div>
                        <div className="testimonial-name">{testimonial.name}</div>
                        <div className="testimonial-role">{testimonial.role}</div>
                      </div>
                      <Rating value={parseFloat(testimonial.rating.toFixed(1))} readOnly stars={5} cancel={false} precision={0.5} className="testimonial-rating" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="selected-testimonial">
              <div className="selected-testimonial-detail">
                <div className="testimonial-description">
                  <div className='testimonialpage-title'>{selectedTestimonial.title}</div>
                  <p>{selectedTestimonial.message}</p>
                </div>
              </div>
            </div>
          </div> */}
        <div className="t-reviews grid">
          {testimonials.map(testimonial => (
            <div className="col-12 md:col-12 lg:col-6 xl:col-3">
              <div className="tm-review">
                <div className="flex flex-wrap">
                  <div>
                    <img src={testimonial.imageUrl} alt={`${testimonial.name}`} width={50} />
                  </div>
                  <div>
                    <div className="s-title">{testimonial.name}</div>
                    <div className="s-subtitle">{testimonial.role}</div>
                  </div>
                </div>
                <div className="review-m-title">{testimonial.title}</div>
                <div className="t-mobile-desc">{testimonial.message}</div>
                <div className="r-ratings">
                  <Rating value={parseFloat(testimonial.rating.toFixed(1))} readOnly stars={5} cancel={false} precision={0.5} className="testimonial-rating" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FooterSection />

    </>
  );
};

export default Testimonials;


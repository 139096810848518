import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./writers-letters-recommendation.scss";
import Illustration from '../../../../../../Assets/Student/tableilustration.svg';
import { Image } from 'primereact/image';


const WritersLettersRecommendation = ({getApiResponse}) => {

    const [toggleForm, setToggleForm] = useState(false);
    const [newData, setNewData] = useState([]);
    const localStorageKey = 'WritersLettersRecommendation';
    const [editIndex, setEditIndex] = useState(null);


    useEffect(() => {
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        if (getApiResponse?.letterOfRecommendations?.length > 0) {
          const response = getApiResponse?.letterOfRecommendations;
          const latestVersionNumber = ((response.length) - 1);
          const latestLetterOfRecommendations = getApiResponse?.letterOfRecommendations[latestVersionNumber];
          if (latestLetterOfRecommendations?.recommendations.length > 0) {
            const getLetterOfRecommendations = latestLetterOfRecommendations.recommendations.map((item) => {
                // var startvDateVal = item.startDate;
                // const [year, month, day] = startvDateVal.split('-');
                // // Format the date as mm/yyyy
                // const formattedStartDate = `${month}/${year}`;
                // var endDateVal = item.endDate;
                // const [eyear, emonth, eday] = endDateVal.split('-');
                // // Format the date as mm/yyyy
                // const formattedEndDate = `${emonth}/${eyear}`;
              return {
                name: item?.name,
                relationship: item?.relationship,
                email: item?.email,
                    
              };
            });
            setNewData(getLetterOfRecommendations);
            localStorage.setItem(localStorageKey, JSON.stringify(getLetterOfRecommendations));
          }
        }
      }, [getApiResponse]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newTableData = {
            name: formData.name,
            relationship: formData.relationship,
            email: formData.email,
        };

        if (editIndex !== null) {
            const updatedData = [...newData];
            const editedData = {
                name: formData.name,
                relationship: formData.relationship,
                email: formData.email,
            };
            updatedData[editIndex] = editedData;
            setNewData(updatedData);
            setEditIndex(null);
            setFormData(data);
            localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
          } else {

        const updatedFormData = [...newData, newTableData];

        setFormData(data);

        localStorage.setItem(localStorageKey, JSON.stringify(updatedFormData));
          }
        const storedData = localStorage.getItem(localStorageKey);
        if (storedData) {
            setNewData(JSON.parse(storedData));
        }

        handleClick();
    };


    const data = {
        name: '',
        relationship: '',
        email: '',
    };

    const [formData, setFormData] = useState(data);


    const handleClick = () => {
        setEditIndex(null);
        setFormData(data);
        setToggleForm(!toggleForm);
      };
    
      const handleEdit = (rowData, index) => {
        setToggleForm(true);
        const startYearValue = new Date(rowData.startYear, 0, 1);
        const endYearValue = new Date(rowData.endYear, 0 , 1);
        setFormData({ ...rowData, startYear: startYearValue, endYear: endYearValue });
        setEditIndex(index);
      };
    
      const handleDelete = (index) => {
        const updatedData = [...newData];
        updatedData.splice(index, 1);
        setNewData(updatedData);
        setEditIndex(null);
        localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
        setFormData(data);
      };

    return (
        <div className='writers-letters-section'>

            {toggleForm ?
                <div className='writers-letters-heading'>
                    <div className='pt-1 pb-2'>Provide information about a person who can write you a recommendation letter</div>
                    <form onSubmit={handleSubmit}>

                        <div className="writer-letters-subheading pt-4">
                            <div className="col-4 flex flex-column">
                                <label>Name of the person</label>
                                <InputText name="name" value={formData.name} onChange={handleChange} required/>
                            </div>
                            <div className="col-4 flex flex-column">
                                <label>Relationship</label>
                                <InputText name="relationship" value={formData.relationship} onChange={handleChange} required/>
                            </div>
                            <div className="col-4 flex flex-column">
                                <label>Email</label>
                                <InputText name="email" value={formData.email} onChange={handleChange} required/>
                            </div>
                        </div>

                        <footer className="Extra-activities-form-footer gap-4">
                            <Button label="Cancel" severity="info" onClick={handleClick} />
                            <Button type="submit" value="Submit" label={editIndex !== null ? "Update Writers" : "Add Writers"} severity="secondary" />
                        </footer>
                    </form>
                </div>
                :
                <div>
                    <div className="grid pt-4 pb-4 notes-alignment" onClick={handleClick}>
                        <div className='col lg:col-6'>Provide information about a person who can write you a recommendation letter</div>
                        <div className="col lg:col-6 addextra-text">+ Add Writers of Letters of Recommendation</div>
                    </div>

                    {newData.length === 0 ? (
                            <div className="flex flex-column justify-content-center align-items-center">
                                <Image src={Illustration} alt="Image" className='writers-image' />
                                <p className='wr-p'>You will see information here when you add your Writers of Letters of Recommendation</p>
                                <Button className='m-3' label="Add Writers of Letters of Recommendation" severity="secondary" onClick={handleClick}/>
                            </div>
                    ) : (
                    <div className="extra-activities-tables pt-4 pb-4">
                        <DataTable value={newData} tableStyle={{ minWidth: '50rem' }} className='datatable-container'>
                            <Column field="name" header="Name of the Person"></Column>
                            <Column field="relationship" header="Relationship"></Column>
                            <Column field="email" header="Email"></Column>
                            <Column
                                    header="Actions"
                                    body={(rowData, column) => (
                                    <div className="actions">
                                        <div>
                                        <Button
                                            icon="pi pi-pencil"
                                            className="p-button-rounded p-button-success mr-1"
                                            onClick={() => handleEdit(rowData, column.rowIndex)}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger ml-1"
                                            onClick={() => handleDelete(column.rowIndex)}
                                        />
                                        </div>
                                    </div>
                                    )}
                                />
                        </DataTable>

                        <ul className=' hide-on-large-screens'>
                                {newData.map((item, index) => (
                                    <li key={item.id}>
                                        <p className='letters-heading'>{item.name}</p>
                                        <div className='px-4 py-4'>
                                            <div className='flex flex-wrap justify-content-evenly align-items-center'> 
                                            <strong >Relationship:</strong><span style={{textAlign:"center"}}> {item.relationship}</span>
                                            </div>

                                            <div className='flex flex-wrap justify-content-evenly align-items-center'> 
                                            <strong >Email:</strong>{item.email}
                                            </div>
                                            <div className="actions p-4 flex justify-content-around align-items-center">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-success mr-1"
                                                    onClick={() => handleEdit(item, index)}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger ml-1"
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </div>
                                        </div>
                                        
                                    </li>
                                ))}
                            </ul>
                    </div>
                    )}
                </div>
            }
        </div>
    )
}
export default WritersLettersRecommendation
import React from "react";
import "./Modeling.scss";
import NewHeader from "../../Home/NewHeader/NewHeader";
import FooterSection from "../../Home/laulama-footer-section/FooterSection";
import MHeaderImage from "../../../Assets/Products/Modeling/Modeling.png";
import MContentImage from "../../../Assets/Products/Modeling/Customized.png";

const Modeling = () => {
  return (
    <>
      <>
        <NewHeader />
        <div className="modeling">
          <div className="pp-header-section grid m-0">
            <div className="col-12 md:col-12 lg:col-6 pp-left">
              <div className="pp-header-content">
                <div className="m-h-title">Modeling</div>
                <p className="pp-h-p">
                  We use modeling to create various models such as visual,
                  graphical, mathematical and computational to make the complex
                  workings of schools clearer and simpler to understand and
                  address.
                </p>
                <p className="pp-h-p">
                  Through Laulama Schools we share models that provide a general
                  perspective of school designs and performance.
                </p>
              </div>
            </div>
            <div className="col-12 md:col-12 lg:col-6 space">
              <img className="m-h-image" src={MHeaderImage} alt="PSimage" />
            </div>
          </div>

          <div className="m-content-section grid">
            <div className="col-12 md:col-12 lg:col-6 pp-left-section space">
              <img className="m-c-image" src={MContentImage} alt="PSimage" />
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="pp-header-content">
                <div className="m-h-title">Customized</div>
                <p className="pp-h-p">
                  We also create customized versions that capture the nuances of
                  individual schools. We constantly compare, contrast and
                  benchmark the models to elevate our understanding of school
                  designs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </>
    </>
  );
};

export default Modeling;

import React, { useState, useEffect } from 'react';
import './Laulamahistory.scss';
import Logo from '../../../Assets/Logo/LOGO.svg';
import { Link } from 'react-router-dom';
import Lines from '../../../Assets/AboutUs/Lines.jpeg';
import LinesLaulama from '../../../Assets/AboutUs/Lineslaulama.jpeg';
import Linesschools from '../../../Assets/AboutUs/Linesschools.jpeg';



const Laulamahistory = () => {

    const [showFullContent1, setShowFullContent1] = useState(false);
    const [showFullContent2, setShowFullContent2] = useState(false);
    const [showFullContent3, setShowFullContent3] = useState(false);
    const [showFullContent4, setShowFullContent4] = useState(false);
   

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const content1 = `
    Laulama Learning and Laulama Schools are the result of a 13-year venture focused on impacting education in Hawaii. This venture started in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions, LLC (OBS).
    `;

    const content2 = `
    OBS’s founder, Mel Horikami, began by learning as much as he could about the challenges facing the public education system in Hawaii. This undertaking involved queries and discussions with a wide range of stakeholders involved in the education arena – business leaders, community members, union representatives, administrators, teachers, students, parents and many others. First-hand knowledge was gained by volunteering time at schools to work with administrators and staff from elementary to high schools and at district and state offices.
    `;

    const content3 = `
    Through these efforts, Mel learned about the persistent “nature” of the educational challenge and realized it was not due to a lack of good instructional practices, programs, resources or dedicated teachers and leaders. He concluded that schools simply needed a way to make all the good inputs work together for increased results and envisioned a platform to serve that purpose.
    `;

    const content4 = `
    Mel’s research indicated that individual schools operated in their own unique way, sharing a common foundation but varying based on locale, administrators and staff, students and other such factors, in how they selected and implemented their best practices. In addition, schools operate within a statewide educational system that is in constant flux as it continues to address and to adapt to evolving circumstances. Based on these findings and the insights provided, Mel concluded that such variables dictated that the platform to be developed had to be adaptive to system changes while meeting the needs of individual schools. Laulama Schools provides that solution.
    `;

    const maxChars = 200;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="OurHistory" className='laulamahistory-section'>
            <div className='flex m-7 col history-section'>
                <div className='col-3 flex justify-content-start align-items-center history-heading'>
                    <p><b>Our History</b></p>
                </div>
                <div className='col-9 grid justify-content-start align-items-center history-para '>
                    <p>
                        {windowWidth <= 1000 ? (
                            <div>
                                {showFullContent1 ? content1 : content1.slice(0, maxChars)}
                                {content1.length > maxChars && (
                                    <Link onClick={() => setShowFullContent1(!showFullContent1)} style={{ color: '#1067B8', fontSize: '16px', paddingLeft: '10px' }}>
                                        {showFullContent1 ? 'See Less' : 'See More'}
                                    </Link>
                                )}
                            </div>
                        ) : (
                            content1
                        )}
                    </p>
                    <p>
                        {windowWidth <= 1000 ? (
                            <div>
                                {showFullContent2 ? content2 : content2.slice(0, maxChars)}
                                {content2.length > maxChars && (
                                    <Link onClick={() => setShowFullContent2(!showFullContent2)} style={{ color: '#1067B8', fontSize: '16px', paddingLeft: '10px' }}>
                                        {showFullContent2 ? 'See Less' : 'See More'}
                                    </Link>
                                )}
                            </div>
                        ) : (
                            content2
                        )}
                    </p>
                    <p>
                        {windowWidth <= 1000 ? (
                            <div>
                                {showFullContent3 ? content3 : content3.slice(0, maxChars)}
                                {content3.length > maxChars && (
                                    <Link onClick={() => setShowFullContent3(!showFullContent3)} style={{ color: '#1067B8', fontSize: '16px', paddingLeft: '10px' }}>
                                        {showFullContent3 ? 'See Less' : 'See More'}
                                    </Link>
                                )}
                            </div>
                        ) : (
                            content3
                        )}
                    </p>
                    <p>
                        {windowWidth <= 1000 ? (
                            <div>
                                {showFullContent4 ? content4 : content4.slice(0, maxChars)}
                                {content4.length > maxChars && (
                                    <Link onClick={() => setShowFullContent4(!showFullContent4)} style={{ color: '#1067B8', fontSize: '16px', paddingLeft: '10px' }}>
                                        {showFullContent4 ? 'See Less' : 'See More'}
                                    </Link>
                                )}
                            </div>
                        ) : (
                            content4
                        )}
                    </p>
                </div>
            </div>


            <div className='Laulamaschools'>
                <div className='flex m-7 col anatomy-section'>
                    <div className='col-3 flex justify-content-start align-items-center anatomy-heading'>
                        <p><b>Logo Anatomy</b></p>
                    </div>
                    <div className='col-9 anatomy-logo-section'>
                        <div className='flex justify-content-center align-items-end logo-torch'>
                            <div className='dotted'>
                                <img src={Lines} alt='Lines' />
                            </div>
                            <div className="logo-description">
                                <p>
                                    One side of the icon, we feature a <b style={{ color: "#91BF82" }}>torch or light</b>, on the other side, we include a leaf from the Lama Tree.
                                </p>
                                <p>
                                    Collectively, representing the knowledge, inspiration, enlightenment, guardianship that is illuminating and paving the path for educational transformation.
                                </p>
                            </div>
                        </div>
                        <div className='flex justify-content-start align-items-center'>
                            <div className='flex justify-content-start align-items-center logo-section'>
                                <img src={Logo} alt='Logo' />
                            </div>
                            <div>
                                <img src={LinesLaulama} alt='LinesLaulama' />
                            </div>
                            <div className=' logo-laulama'>
                                <p>
                                    <b style={{ color: "#91BF82" }}> Lau</b> stands for Many <br></br>
                                    <b style={{ color: "#91BF82" }}> Lama </b> is torches or lights, it is also the name of a tree used for sacred structures such as Hula Halau or as an enclosure for Alii. It is used because of its connotation as a light, a source of knowledge, and a guardian.
                                </p>
                                <p>
                                    Together it is learning from many sources of knowledge and wisdom.
                                </p>
                            </div>
                        </div>
                        <div className=' grid justify-content-end align-items-center logo-schools'>
                            <div>
                                <img src={Linesschools} alt='Linesschools' />
                            </div>
                            <p>
                                <b style={{ color: "#91BF82" }}> Schools</b> represents the networked improvement community that is working together to accelerate and scale improvement learning and results.
                            </p>
                        </div>
                    </div>
                </div>

                <div id='strategicPartners' className="m-6">
                    <h2 className='s-partners pb-3 my-2'>Strategic Partners</h2>
                    <div className="sponsor-heading">
                        <div className='sponsership pb-2'>School Sponsorship</div>
                        <div className='p-sponsership pb-2'>
                        We partner with various business and community organizations to provide schools the support they need to be a participant in the Laulama Schools Network. Sponsoring a school can make the difference for our educators, and for our students’ future. 
                        </div>
                    </div>
                    {/* <div className="savings-heading">
                        <h3> American Savings Bank</h3>
                        <p>
                            Aenean at aliquam quam. Aenean varius lectus nec eros efficitur gravida. Suspendisse potenti.
                            Mauris blandit tincidunt augue, a accumsan neque congue eu. Vivamus congue vulputate mi ut vehicula.
                        </p>
                    </div> */}
                </div>
            </div>


            {window.innerWidth <= 1008 && (
                <>
                    <div className='flex m-7 col anatomy-section'>
                        <div className='col-3 flex justify-content-start align-items-center anatomy-heading'>
                            <p className='m-0'><b>Logo Anatomy</b></p>
                        </div>
                        <div className='flex justify-content-start align-items-center'>
                            <img src={Logo} alt='Logo' style={{ width: "12rem" }} />
                        </div>
                        <div className='flex logo-section'>
                            <div className='col grid justify-content-start align-items-center anatomy-img '>
                                <div className='flex justify-content-start align-items-center anatomy-para'>
                                    <p className='px-2'>
                                        One side of the icon, we feature a <b style={{ color: "#91BF82" }}>torch or light</b>, on the other side, we include a leaf from the Lama Tree.
                                    </p>
                                    <p className='px-2'>
                                        Collectively, representing the knowledge, inspiration, enlightenment, guardianship that is illuminating and paving the path for educational transformation.
                                    </p>
                                </div>
                                <div className='flex justify-content-start align-items-center anatomy-para '>
                                    <p className='px-2'>
                                        <b style={{ color: "#91BF82" }}> Lau</b> stands for Many <br></br>
                                        <b style={{ color: "#91BF82" }}> Lama </b> is torches or lights, it is also the name of a tree used for sacred structures such as Hula Halau or as an enclosure for Alii. It is used because of its connotation as a light, a source of knowledge, and a guardian.
                                        Together it is learning from many sources of knowledge and wisdom.
                                    </p>
                                </div>
                                <div className='flex justify-content-evenly align-items-center anatomy-para '>
                                    <p className='px-2'>
                                        <b style={{ color: "#91BF82" }}> Schools</b> represents the networked improvement community that is working together to accelerate and scale improvement learning and results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Laulamahistory
